import React, { createContext, useState, useEffect } from "react";
import sampleItems from "./sampleItems";

console.log(`sampleItems`, sampleItems);

// Helper functions

const move = (array, oldIndex, newIndex) => {
  if (newIndex >= array.length) {
    newIndex = array.length - 1;
  }
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  return array;
};

const moveElement = (array, index, offset) => {
  const newIndex = index + offset;

  return move(array, index, newIndex);
};

// Context

const GridContext = createContext();

export const GridProvider = ({ children }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(sampleItems);
  }, []);

  const moveItem = (sourceId, destinationId) => {
    console.log("CALLING MOVE ELEMENT!!!");
    const sourceIndex = items.findIndex((item) => item.id === sourceId);
    const destinationIndex = items.findIndex(
      (item) => item.id === destinationId
    );

    // If source/destination is unknown, do nothing.
    if (sourceId === -1 || destinationId === -1) {
      return;
    }

    const offset = destinationIndex - sourceIndex;

    setItems(moveElement(items, sourceIndex, offset));
  };

  return (
    <GridContext.Provider value={{ items, moveItem }}>
      {children}
    </GridContext.Provider>
  );
};

export default GridContext;
