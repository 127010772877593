const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const deleteBookMutation = async ({ id, user }) => {
  console.log("CALLING DELETE BOOK");
  await fetch(`${BACKEND_URL}/book`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: {
        user,
        id,
      },
    }),
  })
    .then((resp) => resp.json())
    .then((data) => console.log("DATA FROM MUTATION : ", data));
};
