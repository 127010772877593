import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LocalStateContext } from "../State/LocalState";
import { styled } from "styled-components";

const CardGrid = styled.div`
  display: grid;
  margin-top: 4rem; /* 64px */
  padding-left: 4rem; /* 64px */
  padding-right: 4rem; /* 64px */

  gap: 4rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
`;

const Card = styled.div`
  border-radius: 0.25rem; /* 4px */
  font-weight: 500;
  font-size: 1.25rem;
  cursor: pointer;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  height: 12rem; /* 192px */
`;

const ReadingHistory = () => {
  const { finishedBooks } = useContext(LocalStateContext);
  return (
    <CardGrid>
      {Object.keys(finishedBooks)
        .sort((a, b) => Number(b) - Number(a))
        .map((year) => (
          <Link to={`/history/${year}`}>
            <Card style={{ backgroundColor: "#343a40" }}>{year}</Card>
          </Link>
        ))}
    </CardGrid>
  );
};

export default ReadingHistory;
