import React, { useState, useContext } from "react";

import toast, { Toaster } from "react-hot-toast";

import dumb from "../data/books";

import { styled } from "styled-components";

import { LocalStateContext } from "../State/LocalState";

import { syncGoodReads } from "../Mutations/SyncGoodReads";

import { scrapeBookGoodReads } from "../Mutations/ScrapeBookGoodreads";
import { addBook } from "../Mutations/AddBook";
import ScrollTopButton from "./ScrollTopButton";

export const ControlColumn = () => {
  const [loading, toggleLoading] = useState(false);
  const [goodReadsURL, setGoodReadsURL] = useState("");

  const GRBooks = dumb.GoodreadsResponse.reviews.Treview;

  const notify = (book) =>
    toast.success(`Successfully added ${book.title} by ${book.author}.`);

  const scrapeAndToast = async () => {
    const bookData = await scrapeAndAddBook();
    notify(bookData);
  };

  const {
    setSorting,
    setDragable,
    bookList,
    setBookList,
    user,
    sortUserBooks,
  } = useContext(LocalStateContext);

  const scrapeAndAddBook = async () => {
    console.log("GOING TO SCRAPE AND ADD BOOK FROM CONTROL PANEL");
    if (goodReadsURL !== "") {
      const scrapedBook = await scrapeBookGoodReads(
        goodReadsURL,
        bookList.length
      );
      const bookData = scrapedBook.payload.book;
      console.log("bookData in scrapeAndAddBook", bookData);

      console.log("bookList.length", bookList.length);

      const newBook = await addBook({
        bookData,
        user,
        length: bookList.length,
      });

      console.log("newBook", newBook);

      let bookToList = newBook.payload.book;

      setBookList([...bookList, { ...bookToList }]);
      setSorting("ORDER_ASC");
      setDragable(true);
      return bookData;
    }
  };

  const sync = async () => {
    toggleLoading(true);

    await syncGoodReads(GRBooks);

    toggleLoading(false);
  };

  const ColumnContainer = styled.div`
    padding: 1.5rem !important;
    margin-top: 3rem !important;
    margin-left: 1.5rem !important;
    flex-direction: column !important;
    display: flex !important;
    border: 1px solid #dee2e6;
    border-top: 6px solid #1a5970;

    h3 {
      font-weight: 700;
      margin-top: 0.5rem; /* 8px */
      margin-bottom: 0.5rem; /* 8px */
      font-size: 1.75rem; /* 18px */
      line-height: 1.75rem; /* 28px */
    }
  `;

  const ControlItem = styled.div`
    margin-bottom: 0.75rem; /* 12px */
  `;

  const ControlButton = styled.div`
  display: flex;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  border-radius: 0.25rem; /* 4px */
  color: #6c757d;
  border-color: #6c757d;
  height: 2rem;
  display: inline-block;
  padding: .375rem .75rem;
  color: #6c757d;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #6c757d;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);
 &:hover,
  &:focus {
    background-color: #a3bcc5;
    font-weight: 600;
    color: black;
  }
  

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;

  `;

  return (
    <ColumnContainer>
      {/* <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Sync With GoodReads
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Note this is an experimental feature. It is best used to seed a
              booklist. Once you have already established an ordered booklist
              and saved, it is recommended not to re-sync. Are you sure you
              would like to sync?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-warning"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-info"
                data-dismiss="modal"
                onClick={() => sync()}
              >
                Sync GoodReads
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="AddGoodReadsModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalLabel">
                <strong>Add Book from GoodReads</strong>
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Add a book to your list from a GoodReads URL provided below
              <form>
                <label for="inputURL" class="form-label">
                  <strong>URL:</strong>
                </label>
                <input
                  type="text"
                  id="inputURL"
                  class="form-control"
                  value={goodReadsURL}
                  onChange={(e) => setGoodReadsURL(e.target.value)}
                />
                <div id="inputURLHelp" class="form-text">
                  If successful, the book will be placed at the bottom of your
                  current list.
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-warning"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-info"
                data-dismiss="modal"
                onClick={() => {
                  scrapeAndToast();
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div> */}
      <ControlItem>
        <h3 className="mb-2 mt-2 text-lg font-bold">Edit noveList</h3>
        <ControlButton
          data-toggle="modal"
          data-target="#AddGoodReadsModal"
          type="button"
        >
          Add Book to noveList Library
        </ControlButton>
      </ControlItem>
      <ControlItem>
        <ControlButton
          onClick={() => {
            sortUserBooks("ORDER_ASC");

            setDragable(true);
          }}
        >
          <strong>Edit Reading Order</strong>
        </ControlButton>
      </ControlItem>
      <ControlItem>
        {!loading && (
          <ControlButton
            data-toggle="modal"
            data-target="#exampleModal"
            type="button"
          >
            Sync With GoodReads
          </ControlButton>
        )}
        {loading && (
          <ControlButton type="button" disabled>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </ControlButton>
        )}
      </ControlItem>
      <h3 className="mb-2 mt-2 text-lg font-bold">Sort Books</h3>
      <ControlItem>
        <ControlButton
          onClick={() => {
            sortUserBooks("averageRating_DESC");
          }}
        >
          Best Rated
        </ControlButton>
      </ControlItem>
      <ControlItem>
        <ControlButton
          onClick={() => {
            sortUserBooks("averageRating_ASC");
          }}
        >
          Worst Rated
        </ControlButton>
      </ControlItem>
      <ControlItem>
        <ControlButton
          onClick={() => {
            sortUserBooks("title_ASC");
          }}
        >
          A - Z
        </ControlButton>
      </ControlItem>
      <ControlItem>
        <ControlButton
          onClick={() => {
            sortUserBooks("title_DESC");
          }}
        >
          Z - A
        </ControlButton>
      </ControlItem>
      <ControlItem>
        <ControlButton
          onClick={() => {
            sortUserBooks("pages_DESC");
          }}
        >
          Longest - Shortest
        </ControlButton>
      </ControlItem>
      <ControlItem>
        <ControlButton
          onClick={() => {
            sortUserBooks("pages_ASC");
          }}
        >
          Shortest - Longest
        </ControlButton>
      </ControlItem>

      <Toaster
        toastOptions={{
          className: "border-l-8 border-green-400 h-20 w-1/4 text-md ",
        }}
      />
      <ScrollTopButton />
    </ColumnContainer>
  );
};
