import React, { useState, useContext, useCallback, useEffect } from "react";

import { LocalStateContext } from "../State/LocalState";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Card } from "./DnD/Card";
import update from "immutability-helper";

import { styled } from "styled-components";

export const Container = () => {
  const { bookList } = useContext(LocalStateContext);

  const [shelfList, setShelfList] = useState(bookList);

  useEffect(() => {
    setShelfList(bookList);
  }, [bookList]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = shelfList[dragIndex];
      setShelfList(
        update(shelfList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [shelfList]
  );

  const ShelfContainer = styled.div`
    max-width: 100%;
    padding-left: 4rem; /* 64px */
    padding-right: 4rem; /* 64px */
    padding-top: 2rem; /* 32px */
    padding-bottom: 2rem; /* 32px */
    overflow: hidden;
  `;

  const BookGrid = styled.div`
    overflow: hidden;
    display: grid;
    grid-auto-rows: auto;
    gap: 3rem; /* 48px */

    @media (min-width: 475px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: 640px) {
      gap: 4rem;
    }

    @media (min-width: 768px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media (min-width: 1280px) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
  `;

  return (
    <ShelfContainer>
      <BookGrid>
        {console.log("shelfList", shelfList)}
        {shelfList.map(({ book }, i) => (
          <Card
            key={book._id}
            index={i}
            id={book._id}
            text={book.text}
            image={book.image}
            moveCard={moveCard}
          />
        ))}
      </BookGrid>
    </ShelfContainer>
  );
};

const Shelf = () => {
  console.log("SHELF IS LOADED");
  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <Container />
      </DndProvider>
    </div>
  );
};

export default Shelf;
