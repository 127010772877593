import React from "react";

const Calendar = ({ size = "1.5rem", fill = "#fff", stroke = "black" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      dataReactroot=""
    >
      <path
        strokeWidth="1"
        stroke="#221b38"
        d="M19 2.5C19 2.22386 19.2239 2 19.5 2V2C19.7761 2 20 2.22386 20 2.5V5H19V2.5Z"
      ></path>
      <path
        strokeWidth="1"
        stroke="#221b38"
        d="M14 2.5C14 2.22386 14.2239 2 14.5 2V2C14.7761 2 15 2.22386 15 2.5V5H14V2.5Z"
      ></path>
      <path
        strokeWidth="1"
        stroke="#221b38"
        d="M4 2.5C4 2.22386 4.22386 2 4.5 2V2C4.77614 2 5 2.22386 5 2.5V5H4V2.5Z"
      ></path>
      <path
        strokeWidth="1"
        stroke="#221b38"
        d="M9 2.5C9 2.22386 9.22386 2 9.5 2V2C9.77614 2 10 2.22386 10 2.5V5H9V2.5Z"
      ></path>
      <path
        strokeWidth="1"
        stroke="#221b38"
        fill="#ffffff"
        d="M2 7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V19.6284L19.451 22H4C2.89543 22 2 21.1046 2 20V7Z"
        clipRule="evenodd"
        fillRule="evenodd"
      ></path>
      <rect
        strokeWidth="1"
        stroke="#221b38"
        height="1"
        width="1"
        y="9"
        x="6"
      ></rect>
      <rect
        strokeWidth="1"
        stroke="#221b38"
        height="1"
        width="1"
        y="13"
        x="6"
      ></rect>
      <rect
        strokeWidth="1"
        stroke="#221b38"
        height="1"
        width="1"
        y="17"
        x="6"
      ></rect>
      <rect
        strokeWidth="1"
        stroke="#221b38"
        height="1"
        width="1"
        y="13"
        x="17"
      ></rect>
      <rect
        strokeWidth="1"
        stroke="#221b38"
        height="1"
        width="1"
        y="17"
        x="17"
      ></rect>
      <rect
        strokeWidth="1"
        stroke="#221b38"
        height="1"
        width="1"
        y="13"
        x="11.5"
      ></rect>
      <rect
        strokeWidth="1"
        stroke="#221b38"
        height="1"
        width="1"
        y="17"
        x="11.5"
      ></rect>
      <rect
        strokeWidth="1"
        stroke="#221b38"
        height="1"
        width="1"
        y="9"
        x="11.5"
      ></rect>
      <rect
        strokeWidth="1"
        stroke="#221b38"
        height="1"
        width="1"
        y="9"
        x="17"
      ></rect>
    </svg>
  );
};

export default Calendar;
