const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const markBookAsReadMutation = async ({
  userId,
  books,
  bookId,
  currentYear,
}) => {
  console.log("CALLING MARK BOOK AS READ MUTATION");

  console.log("currentYear", currentYear);

  const formattedBooks = books.map((book, index) => {
    return {
      book: book.book._id,
      order: index,
    };
  });

  await fetch(`${BACKEND_URL}/markcomplete`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: {
        user: userId,
        books: formattedBooks,
        bookId,
        currentYear,
      },
    }),
  })
    .then((resp) => resp.json())
    .then((data) => console.log("DATA FROM MUTATION : ", data));
};
