import React from "react";

const IllustrationBookshelf = ({ width = "200", height = "150" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 800 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      dataReactroot=""
    >
      <path
        d="M634.148 25.2007H452.259V486.308H634.148V25.2007Z"
        fill="#ffffff"
      ></path>
      <g opacity="0.1">
        <path
          d="M554.233 129.375H545.203V165.962H554.233V129.375Z"
          fill="black"
        ></path>
        <path
          d="M567.263 129.375H558.233V165.962H567.263V129.375Z"
          fill="black"
        ></path>
        <path
          d="M580.292 129.375H571.263V165.962H580.292V129.375Z"
          fill="black"
        ></path>
        <path
          d="M593.321 129.375H584.292V165.962H593.321V129.375Z"
          fill="black"
        ></path>
        <path
          d="M502.115 129.375H493.086V165.962H502.115V129.375Z"
          fill="black"
        ></path>
        <path
          d="M515.145 129.375H506.115V165.962H515.145V129.375Z"
          fill="black"
        ></path>
        <path
          d="M528.174 129.375H519.145V165.962H528.174V129.375Z"
          fill="black"
        ></path>
        <path
          d="M541.203 129.375H532.174V165.962H541.203V129.375Z"
          fill="black"
        ></path>
      </g>
      <g opacity="0.1">
        <path
          d="M554.233 200.587H545.203V237.174H554.233V200.587Z"
          fill="black"
        ></path>
        <path
          d="M567.263 200.587H558.233V237.174H567.263V200.587Z"
          fill="black"
        ></path>
        <path
          d="M580.292 200.587H571.263V237.174H580.292V200.587Z"
          fill="black"
        ></path>
        <path
          d="M593.321 200.587H584.292V237.174H593.321V200.587Z"
          fill="black"
        ></path>
        <path
          d="M502.115 200.587H493.086V237.174H502.115V200.587Z"
          fill="black"
        ></path>
        <path
          d="M515.145 200.587H506.115V237.174H515.145V200.587Z"
          fill="black"
        ></path>
        <path
          d="M528.174 200.587H519.145V237.174H528.174V200.587Z"
          fill="black"
        ></path>
        <path
          d="M541.203 200.587H532.174V237.174H541.203V200.587Z"
          fill="black"
        ></path>
      </g>
      <path
        d="M316.15 133.328H175.85V486.308H316.15V133.328Z"
        fill="#ffffff"
      ></path>
      <path
        d="M257.028 160.697H247.999V197.283H257.028V160.697Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M270.059 160.697H261.029V197.283H270.059V160.697Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M283.088 160.697H274.059V197.283H283.088V160.697Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M296.117 160.697H287.088V197.283H296.117V160.697Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M204.911 160.697H195.882V197.283H204.911V160.697Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M217.941 160.697H208.911V197.283H217.941V160.697Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M230.97 160.697H221.94V197.283H230.97V160.697Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M243.999 160.697H234.97V197.283H243.999V160.697Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M186.51 32.0977V133.328H175.85V486.308H0V32.0977H186.51Z"
        fill="#f5a623"
      ></path>
      <g opacity="0.1">
        <path
          d="M118.814 347.699H109.784V390.61H118.814V347.699Z"
          fill="#ffffff"
        ></path>
        <path
          d="M132.843 347.699H123.813V390.61H132.843V347.699Z"
          fill="#ffffff"
        ></path>
        <path
          d="M146.872 347.699H137.843V390.61H146.872V347.699Z"
          fill="#ffffff"
        ></path>
        <path
          d="M160.901 347.699H151.872V390.61H160.901V347.699Z"
          fill="#ffffff"
        ></path>
        <path
          d="M76.7247 347.699H67.6953V390.61H76.7247V347.699Z"
          fill="#ffffff"
        ></path>
        <path
          d="M62.6954 347.699H53.666V390.61H62.6954V347.699Z"
          fill="#ffffff"
        ></path>
        <path
          d="M48.6661 347.699H39.6367V390.61H48.6661V347.699Z"
          fill="#ffffff"
        ></path>
        <path
          d="M34.6368 347.699H25.6074V390.61H34.6368V347.699Z"
          fill="#ffffff"
        ></path>
      </g>
      <g opacity="0.1">
        <path
          d="M118.814 68.9634H109.784V111.875H118.814V68.9634Z"
          fill="#ffffff"
        ></path>
        <path
          d="M146.872 68.9634H137.843V111.875H146.872V68.9634Z"
          fill="#ffffff"
        ></path>
        <path
          d="M160.901 68.9634H151.872V111.875H160.901V68.9634Z"
          fill="#ffffff"
        ></path>
        <path
          d="M104.784 68.9634H95.7549V111.875H104.784V68.9634Z"
          fill="#ffffff"
        ></path>
        <path
          d="M90.754 68.9634H81.7246V111.875H90.754V68.9634Z"
          fill="#ffffff"
        ></path>
        <path
          d="M76.7247 68.9634H67.6953V111.875H76.7247V68.9634Z"
          fill="#ffffff"
        ></path>
        <path
          d="M62.6954 68.9634H53.666V111.875H62.6954V68.9634Z"
          fill="#ffffff"
        ></path>
        <path
          d="M48.6661 68.9634H39.6367V111.875H48.6661V68.9634Z"
          fill="#ffffff"
        ></path>
        <path
          d="M34.6368 68.9634H25.6074V111.875H34.6368V68.9634Z"
          fill="#ffffff"
        ></path>
      </g>
      <g opacity="0.1">
        <path
          d="M118.814 161.875H109.784V204.786H118.814V161.875Z"
          fill="#ffffff"
        ></path>
        <path
          d="M132.843 161.875H123.813V204.786H132.843V161.875Z"
          fill="#ffffff"
        ></path>
        <path
          d="M146.872 161.875H137.843V204.786H146.872V161.875Z"
          fill="#ffffff"
        ></path>
        <path
          d="M160.901 161.875H151.872V204.786H160.901V161.875Z"
          fill="#ffffff"
        ></path>
        <path
          d="M104.784 161.875H95.7549V204.786H104.784V161.875Z"
          fill="#ffffff"
        ></path>
        <path
          d="M90.754 161.875H81.7246V204.786H90.754V161.875Z"
          fill="#ffffff"
        ></path>
        <path
          d="M76.7247 161.875H67.6953V204.786H76.7247V161.875Z"
          fill="#ffffff"
        ></path>
        <path
          d="M48.6661 161.875H39.6367V204.786H48.6661V161.875Z"
          fill="#ffffff"
        ></path>
        <path
          d="M34.6368 161.875H25.6074V204.786H34.6368V161.875Z"
          fill="#ffffff"
        ></path>
      </g>
      <g opacity="0.1">
        <path
          d="M118.814 254.787H109.784V297.698H118.814V254.787Z"
          fill="#ffffff"
        ></path>
        <path
          d="M132.843 254.787H123.813V297.698H132.843V254.787Z"
          fill="#ffffff"
        ></path>
        <path
          d="M146.872 254.787H137.843V297.698H146.872V254.787Z"
          fill="#ffffff"
        ></path>
        <path
          d="M160.901 254.787H151.872V297.698H160.901V254.787Z"
          fill="#ffffff"
        ></path>
        <path
          d="M90.754 254.787H81.7246V297.698H90.754V254.787Z"
          fill="#ffffff"
        ></path>
        <path
          d="M76.7247 254.787H67.6953V297.698H76.7247V254.787Z"
          fill="#ffffff"
        ></path>
        <path
          d="M62.6954 254.787H53.666V297.698H62.6954V254.787Z"
          fill="#ffffff"
        ></path>
        <path
          d="M48.6661 254.787H39.6367V297.698H48.6661V254.787Z"
          fill="#ffffff"
        ></path>
        <path
          d="M34.6368 254.787H25.6074V297.698H34.6368V254.787Z"
          fill="#ffffff"
        ></path>
      </g>
      <path
        d="M799.999 134.002H634.148V486.308H799.999V134.002Z"
        fill="#f5a623"
      ></path>
      <g opacity="0.1">
        <path
          d="M554.233 271.8H545.203V308.387H554.233V271.8Z"
          fill="black"
        ></path>
        <path
          d="M567.263 271.8H558.233V308.387H567.263V271.8Z"
          fill="black"
        ></path>
        <path
          d="M580.292 271.8H571.263V308.387H580.292V271.8Z"
          fill="black"
        ></path>
        <path
          d="M593.321 271.8H584.292V308.387H593.321V271.8Z"
          fill="black"
        ></path>
        <path
          d="M502.115 271.8H493.086V308.387H502.115V271.8Z"
          fill="black"
        ></path>
        <path
          d="M515.145 271.8H506.115V308.387H515.145V271.8Z"
          fill="black"
        ></path>
        <path
          d="M528.174 271.8H519.145V308.387H528.174V271.8Z"
          fill="black"
        ></path>
        <path
          d="M541.203 271.8H532.174V308.387H541.203V271.8Z"
          fill="black"
        ></path>
      </g>
      <g opacity="0.1">
        <path
          d="M554.233 58.1626H545.203V94.7493H554.233V58.1626Z"
          fill="black"
        ></path>
        <path
          d="M567.263 58.1626H558.233V94.7493H567.263V58.1626Z"
          fill="black"
        ></path>
        <path
          d="M580.292 58.1626H571.263V94.7493H580.292V58.1626Z"
          fill="black"
        ></path>
        <path
          d="M593.321 58.1626H584.292V94.7493H593.321V58.1626Z"
          fill="black"
        ></path>
        <path
          d="M502.115 58.1626H493.086V94.7493H502.115V58.1626Z"
          fill="black"
        ></path>
        <path
          d="M515.145 58.1626H506.115V94.7493H515.145V58.1626Z"
          fill="black"
        ></path>
        <path
          d="M528.174 58.1626H519.145V94.7493H528.174V58.1626Z"
          fill="black"
        ></path>
        <path
          d="M541.203 58.1626H532.174V94.7493H541.203V58.1626Z"
          fill="black"
        ></path>
      </g>
      <path
        d="M257.028 222.616H247.999V259.202H257.028V222.616Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M270.059 222.616H261.029V259.202H270.059V222.616Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M283.088 222.616H274.059V259.202H283.088V222.616Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M296.117 222.616H287.088V259.202H296.117V222.616Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M204.911 222.616H195.882V259.202H204.911V222.616Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M217.941 222.616H208.911V259.202H217.941V222.616Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M230.97 222.616H221.94V259.202H230.97V222.616Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M243.999 222.616H234.97V259.202H243.999V222.616Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M592.787 245.03C594.229 246.423 595.711 247.765 597.233 249.054"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M606.544 255.984C630.213 271.406 660.707 276.05 690.271 268.46"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke-dasharray="11.61 11.61"
      ></path>
      <path
        d="M695.869 266.873C697.77 266.282 699.666 265.64 701.553 264.945"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M582.83 114.052C549.773 149.342 557.828 211.242 592.787 245.03"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke-dasharray="12 12"
      ></path>
      <path
        d="M696.957 102.562C695.208 101.678 693.395 100.827 691.526 100.013"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke-dasharray="12 12"
      ></path>
      <path
        d="M680.028 95.7144C652.467 86.9996 617.349 86.6031 591.85 105.922"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke-dasharray="12 12"
      ></path>
      <path
        d="M587.109 109.847C585.642 111.172 584.214 112.573 582.829 114.052"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke-dasharray="12 12"
      ></path>
      <path
        d="M758.199 250.137C760.805 229.517 759.061 211.849 758.234 205.201C756.216 188.984 748.719 128.725 696.957 102.562"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M251.17 79.5835C253.119 80.1162 255.032 80.6733 256.926 81.2671"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M268.195 85.3623C288.212 93.7183 309.567 108.73 351.51 143.924C491.109 261.056 504.093 302.519 575.936 328.52C590.51 333.795 673.317 363.765 723.765 320.86C742.731 304.73 751.976 282.68 756.244 261.973"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke-dasharray="12 12"
      ></path>
      <path
        d="M757.337 256.07C757.667 254.071 757.952 252.091 758.199 250.137"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M33.8652 201.433C39.6761 169.712 54.7934 139.61 78.0631 116.35C140.729 53.7095 229.523 73.6705 251.17 79.5835"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <g opacity="0.1">
        <path
          d="M554.233 343.012H545.203V379.598H554.233V343.012Z"
          fill="black"
        ></path>
        <path
          d="M567.263 343.012H558.233V379.598H567.263V343.012Z"
          fill="black"
        ></path>
        <path
          d="M580.292 343.012H571.263V379.598H580.292V343.012Z"
          fill="black"
        ></path>
        <path
          d="M593.321 343.012H584.292V379.598H593.321V343.012Z"
          fill="black"
        ></path>
        <path
          d="M502.115 343.012H493.086V379.598H502.115V343.012Z"
          fill="black"
        ></path>
        <path
          d="M515.145 343.012H506.115V379.598H515.145V343.012Z"
          fill="black"
        ></path>
        <path
          d="M528.174 343.012H519.145V379.598H528.174V343.012Z"
          fill="black"
        ></path>
        <path
          d="M541.203 343.012H532.174V379.598H541.203V343.012Z"
          fill="black"
        ></path>
      </g>
      <path
        d="M257.028 383.109H247.999V419.696H257.028V383.109Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M270.059 383.109H261.029V419.696H270.059V383.109Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M283.088 383.109H274.059V419.696H283.088V383.109Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M296.117 383.109H287.088V419.696H296.117V383.109Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M204.911 383.109H195.882V419.696H204.911V383.109Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M217.941 383.109H208.911V419.696H217.941V383.109Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M230.97 383.109H221.94V419.696H230.97V383.109Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <path
        d="M243.999 383.109H234.97V419.696H243.999V383.109Z"
        fill="black"
        fill-opacity="0.15"
      ></path>
      <g opacity="0.1">
        <path
          d="M554.233 414.224H545.203V450.811H554.233V414.224Z"
          fill="black"
        ></path>
        <path
          d="M567.263 414.224H558.233V450.811H567.263V414.224Z"
          fill="black"
        ></path>
        <path
          d="M580.292 414.224H571.263V450.811H580.292V414.224Z"
          fill="black"
        ></path>
        <path
          d="M593.321 414.224H584.292V450.811H593.321V414.224Z"
          fill="black"
        ></path>
        <path
          d="M502.115 414.224H493.086V450.811H502.115V414.224Z"
          fill="black"
        ></path>
        <path
          d="M515.145 414.224H506.115V450.811H515.145V414.224Z"
          fill="black"
        ></path>
        <path
          d="M528.174 414.224H519.145V450.811H528.174V414.224Z"
          fill="black"
        ></path>
        <path
          d="M541.203 414.224H532.174V450.811H541.203V414.224Z"
          fill="black"
        ></path>
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M799.999 488.308H0V484.308H799.999V488.308Z"
        fill="#ffffff"
      ></path>
      <g opacity="0.4">
        <g opacity="0.2">
          <path
            d="M743.363 251.658H734.334V288.245H743.363V251.658Z"
            fill="#ffffff"
          ></path>
          <path
            d="M753.917 251.658H744.888V288.245H753.917V251.658Z"
            fill="#ffffff"
          ></path>
          <path
            d="M764.471 251.658H755.441V288.245H764.471V251.658Z"
            fill="#ffffff"
          ></path>
          <path
            d="M775.025 251.658H765.995V288.245H775.025V251.658Z"
            fill="#ffffff"
          ></path>
        </g>
        <g opacity="0.2">
          <path
            d="M738.086 359.986H729.057V396.573H738.086V359.986Z"
            fill="#ffffff"
          ></path>
          <path
            d="M748.64 359.986H739.61V396.573H748.64V359.986Z"
            fill="#ffffff"
          ></path>
          <path
            d="M759.193 359.986H750.164V396.573H759.193V359.986Z"
            fill="#ffffff"
          ></path>
          <path
            d="M769.747 359.986H760.718V396.573H769.747V359.986Z"
            fill="#ffffff"
          ></path>
        </g>
        <g opacity="0.2">
          <path
            d="M689.481 359.986H680.451V396.573H689.481V359.986Z"
            fill="#ffffff"
          ></path>
          <path
            d="M710.588 359.986H701.559V396.573H710.588V359.986Z"
            fill="#ffffff"
          ></path>
          <path
            d="M721.142 359.986H712.112V396.573H721.142V359.986Z"
            fill="#ffffff"
          ></path>
        </g>
        <g opacity="0.2">
          <path
            d="M661.266 359.986H652.236V396.573H661.266V359.986Z"
            fill="#ffffff"
          ></path>
          <path
            d="M671.819 359.986H662.79V396.573H671.819V359.986Z"
            fill="#ffffff"
          ></path>
        </g>
      </g>
      <path
        d="M642.631 183.713H783.822"
        stroke="#005B9D"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M642.631 238.375H783.822"
        stroke="#005B9D"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M642.631 293.038H783.822"
        stroke="#005B9D"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M642.631 347.7H783.822"
        stroke="#005B9D"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M642.631 402.362H783.822"
        stroke="#005B9D"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M24.4014 118.256H165.593"
        stroke="#005B9D"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M24.4014 213.256H165.593"
        stroke="#005B9D"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M24.4014 308.256H165.593"
        stroke="#005B9D"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M24.4014 403.256H165.593"
        stroke="#005B9D"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M24.4014 498.256H165.593"
        stroke="#005B9D"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M402.742 575.222C615.213 575.222 787.454 559.421 787.454 539.93C787.454 520.438 615.213 504.637 402.742 504.637C190.272 504.637 18.0303 520.438 18.0303 539.93C18.0303 559.421 190.272 575.222 402.742 575.222Z"
        fill="#1a5970"
      ></path>
      <path
        d="M402.742 567.853C596.625 567.853 753.797 555.351 753.797 539.929C753.797 524.508 596.625 512.006 402.742 512.006C208.86 512.006 51.6875 524.508 51.6875 539.929C51.6875 555.351 208.86 567.853 402.742 567.853Z"
        fill="#ffffff"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M518.267 445.924H592.875V453.924H518.267V445.924Z"
        fill="#f5a623"
      ></path>
      <path
        opacity="0.2"
        d="M141.129 548.456C203.307 548.456 253.713 545.241 253.713 541.275C253.713 537.309 203.307 534.094 141.129 534.094C78.9504 534.094 28.5449 537.309 28.5449 541.275C28.5449 545.241 78.9504 548.456 141.129 548.456Z"
        fill="black"
      ></path>
      <path
        d="M78.2642 472.424H73.0518V541.775H78.2642V472.424Z"
        fill="#1a5970"
      ></path>
      <path
        d="M131.934 472.424H126.722V541.775H131.934V472.424Z"
        fill="#1a5970"
      ></path>
      <path
        d="M586.252 439.012L587.487 439.927C589.193 439.927 591.491 437.629 591.491 435.923L589.955 427.605L582.695 428.607C582.695 428.607 584.547 439.012 586.252 439.012Z"
        fill="#1a5970"
      ></path>
      <path
        d="M591.793 435.711C592.519 435.483 593.267 435.958 593.307 436.719C593.424 438.942 593.701 444.234 592.88 445.328C591.693 446.912 555.719 445.954 555.719 444.371C555.719 442.787 581.382 434.306 583.758 435.097C585.744 435.76 589.207 436.521 591.793 435.711Z"
        fill="#13223E"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M568.046 434.968C568.244 434.628 568.679 434.512 569.019 434.709L578.098 439.977C578.438 440.174 578.553 440.61 578.356 440.95C578.159 441.29 577.723 441.405 577.383 441.208L568.305 435.94C567.965 435.743 567.849 435.308 568.046 434.968Z"
        fill="#1a5970"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M574.564 432.334C574.761 431.994 575.197 431.878 575.537 432.076L584.615 437.343C584.955 437.541 585.071 437.976 584.874 438.316C584.676 438.656 584.241 438.772 583.901 438.574L574.822 433.307C574.482 433.109 574.367 432.674 574.564 432.334Z"
        fill="#1a5970"
      ></path>
      <path
        d="M565.779 152.125L566.937 159.959L562.639 163.297L560.237 156.523L561.963 152.917L565.779 152.125Z"
        fill="#E5AF7D"
      ></path>
      <path
        d="M570.795 150.831C570.708 157.158 571.328 163.745 574.694 170.181C574.694 170.181 562.635 167.238 552.685 173.618C553.3 169.528 554.032 166.702 553.601 163.619"
        fill="#E5AF7D"
      ></path>
      <path
        d="M570.795 150.831C570.708 157.158 571.328 163.745 574.694 170.181C574.694 170.181 562.635 167.238 552.685 173.618C553.3 169.528 554.032 166.702 553.601 163.619"
        stroke="#13223E"
        strokeWidth="0.728399"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M562.658 155.22L561.12 161.38C559.419 162.776 547.22 165.006 546.258 163.873C544.095 161.324 542.976 145.411 543.599 139.333C543.86 136.785 544.578 135.62 544.892 133.33L563.184 134.023L571.216 135.755L571.914 147.344L571.216 149.749L570.748 153.687"
        fill="#E5AF7D"
      ></path>
      <path
        d="M562.658 155.22L561.12 161.38C559.419 162.776 547.22 165.006 546.258 163.873C544.095 161.324 542.976 145.411 543.599 139.333C543.86 136.785 544.578 135.62 544.892 133.33L563.184 134.023L571.216 135.755L571.914 147.344L571.216 149.749L570.748 153.687"
        stroke="#13223E"
        strokeWidth="0.728399"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M558.524 154.121L558.572 154.098C558.587 154.098 558.597 154.104 558.6 154.104L558.57 154.225L558.524 154.121Z"
        fill="#231F20"
      ></path>
      <path
        d="M558.57 153.859C558.473 153.859 558.378 153.907 558.317 153.996C558.22 154.138 558.257 154.317 558.401 154.411C558.454 154.445 558.514 154.462 558.572 154.462C558.67 154.462 558.765 154.415 558.826 154.326C558.923 154.183 558.886 154.005 558.742 153.912C558.688 153.876 558.629 153.859 558.57 153.859Z"
        fill="#13223E"
      ></path>
      <path
        d="M549.188 162.151C549.092 162.294 548.909 162.336 548.764 162.242C548.618 162.149 548.586 161.967 548.682 161.825C548.777 161.682 548.96 161.64 549.106 161.734C549.251 161.828 549.284 162.009 549.188 162.151Z"
        fill="#231F20"
      ></path>
      <path
        d="M548.939 161.684C548.839 161.684 548.743 161.734 548.682 161.825C548.586 161.967 548.618 162.148 548.764 162.242C548.816 162.276 548.874 162.292 548.93 162.292C549.03 162.292 549.126 162.242 549.188 162.151C549.283 162.009 549.251 161.828 549.106 161.734C549.053 161.7 548.995 161.684 548.939 161.684Z"
        fill="#13223E"
      ></path>
      <path
        d="M545.188 155.487L545.238 155.464C545.253 155.464 545.262 155.469 545.265 155.471L545.235 155.588L545.188 155.487Z"
        fill="#231F20"
      ></path>
      <path
        d="M545.235 155.224C545.138 155.224 545.044 155.271 544.983 155.36C544.886 155.501 544.923 155.682 545.066 155.775C545.12 155.811 545.18 155.828 545.238 155.828C545.335 155.828 545.429 155.781 545.49 155.692C545.587 155.55 545.551 155.371 545.407 155.277C545.353 155.241 545.293 155.224 545.235 155.224Z"
        fill="#13223E"
      ></path>
      <path
        d="M558.826 156.689C558.729 156.831 558.545 156.864 558.402 156.771C558.258 156.677 558.22 156.499 558.318 156.357C558.415 156.215 558.598 156.182 558.742 156.275C558.885 156.369 558.923 156.547 558.826 156.689Z"
        fill="#231F20"
      ></path>
      <path
        d="M558.568 156.223C558.472 156.223 558.378 156.269 558.318 156.357C558.22 156.499 558.259 156.677 558.402 156.771C558.456 156.806 558.516 156.824 558.575 156.824C558.672 156.824 558.766 156.777 558.826 156.689C558.923 156.547 558.885 156.369 558.741 156.275C558.687 156.24 558.627 156.223 558.568 156.223Z"
        fill="#13223E"
      ></path>
      <path
        d="M545.859 159.919L545.907 159.896C545.921 159.896 545.929 159.901 545.932 159.902L545.905 160.022L545.859 159.919Z"
        fill="#231F20"
      ></path>
      <path
        d="M545.905 159.658C545.807 159.658 545.712 159.706 545.651 159.794C545.554 159.937 545.591 160.116 545.735 160.209C545.788 160.244 545.848 160.261 545.906 160.261C546.004 160.261 546.099 160.213 546.16 160.124C546.257 159.982 546.22 159.803 546.076 159.709C546.022 159.675 545.963 159.658 545.905 159.658Z"
        fill="#13223E"
      ></path>
      <path
        d="M560.798 158.762C560.701 158.904 560.518 158.939 560.374 158.846C560.231 158.752 560.195 158.571 560.292 158.43C560.389 158.288 560.572 158.252 560.715 158.346C560.859 158.441 560.895 158.62 560.798 158.762Z"
        fill="#231F20"
      ></path>
      <path
        d="M560.543 158.294C560.446 158.294 560.352 158.341 560.291 158.43C560.195 158.571 560.231 158.751 560.374 158.846C560.428 158.881 560.488 158.898 560.546 158.898C560.644 158.898 560.738 158.851 560.798 158.762C560.895 158.62 560.859 158.44 560.716 158.346C560.662 158.311 560.602 158.294 560.543 158.294Z"
        fill="#13223E"
      ></path>
      <path
        d="M560.498 152.575L560.545 152.553C560.561 152.553 560.57 152.559 560.573 152.56L560.544 152.676L560.498 152.575Z"
        fill="#231F20"
      ></path>
      <path
        d="M560.545 152.312C560.447 152.312 560.352 152.36 560.291 152.449C560.194 152.591 560.229 152.772 560.374 152.866C560.427 152.9 560.486 152.917 560.544 152.917C560.642 152.917 560.737 152.87 560.798 152.78C560.895 152.638 560.86 152.458 560.716 152.364C560.662 152.329 560.603 152.312 560.545 152.312Z"
        fill="#13223E"
      ></path>
      <path
        d="M547.496 163.126C547.4 163.269 547.216 163.306 547.072 163.212C546.928 163.118 546.891 162.938 546.988 162.795C547.084 162.653 547.267 162.615 547.412 162.71C547.556 162.804 547.592 162.984 547.496 163.126Z"
        fill="#231F20"
      ></path>
      <path
        d="M547.241 162.658C547.143 162.658 547.048 162.706 546.988 162.795C546.891 162.937 546.928 163.119 547.072 163.212C547.126 163.247 547.185 163.264 547.243 163.264C547.341 163.264 547.435 163.216 547.496 163.126C547.592 162.984 547.556 162.804 547.412 162.71C547.358 162.675 547.299 162.658 547.241 162.658Z"
        fill="#13223E"
      ></path>
      <path
        d="M551.65 162.468L551.697 162.446C551.712 162.446 551.723 162.452 551.725 162.454L551.701 162.566L551.65 162.468Z"
        fill="#231F20"
      ></path>
      <path
        d="M551.701 162.202C551.602 162.202 551.507 162.251 551.445 162.341C551.349 162.484 551.382 162.666 551.527 162.759C551.581 162.794 551.64 162.811 551.697 162.811C551.796 162.811 551.891 162.762 551.952 162.672C552.049 162.529 552.015 162.348 551.87 162.253C551.817 162.219 551.759 162.202 551.701 162.202Z"
        fill="#13223E"
      ></path>
      <path
        d="M555.678 161.546C555.582 161.688 555.398 161.726 555.253 161.632C555.108 161.539 555.073 161.358 555.169 161.215C555.266 161.073 555.45 161.035 555.595 161.129C555.739 161.223 555.775 161.403 555.678 161.546Z"
        fill="#231F20"
      ></path>
      <path
        d="M555.424 161.077C555.326 161.077 555.23 161.125 555.169 161.215C555.073 161.358 555.108 161.539 555.253 161.632C555.307 161.667 555.366 161.684 555.424 161.684C555.522 161.684 555.617 161.636 555.678 161.546C555.775 161.403 555.739 161.223 555.595 161.129C555.541 161.094 555.482 161.077 555.424 161.077Z"
        fill="#13223E"
      ></path>
      <path
        d="M553.86 158.143L553.909 158.12C553.925 158.12 553.936 158.126 553.938 158.128L553.908 158.242L553.86 158.143Z"
        fill="#231F20"
      ></path>
      <path
        d="M553.908 157.878C553.811 157.878 553.716 157.926 553.655 158.015C553.558 158.158 553.594 158.337 553.737 158.432C553.791 158.467 553.85 158.483 553.908 158.483C554.006 158.483 554.101 158.436 554.162 158.347C554.258 158.205 554.224 158.025 554.079 157.93C554.026 157.895 553.966 157.878 553.908 157.878Z"
        fill="#13223E"
      ></path>
      <path
        d="M557.691 159.171C557.594 159.314 557.41 159.351 557.265 159.257C557.12 159.164 557.084 158.983 557.181 158.84C557.278 158.698 557.462 158.66 557.607 158.754C557.751 158.848 557.788 159.028 557.691 159.171Z"
        fill="#231F20"
      ></path>
      <path
        d="M557.436 158.702C557.338 158.702 557.242 158.75 557.181 158.84C557.084 158.983 557.12 159.164 557.265 159.257C557.319 159.292 557.378 159.309 557.436 159.309C557.534 159.309 557.63 159.261 557.691 159.171C557.787 159.028 557.751 158.848 557.607 158.754C557.553 158.719 557.494 158.702 557.436 158.702Z"
        fill="#13223E"
      ></path>
      <path
        d="M551.341 157.337L551.389 157.315C551.406 157.315 551.417 157.322 551.42 157.324L551.393 157.433L551.341 157.337Z"
        fill="#231F20"
      ></path>
      <path
        d="M551.394 157.068C551.296 157.068 551.201 157.117 551.139 157.208C551.042 157.35 551.075 157.533 551.22 157.628C551.273 157.664 551.331 157.68 551.389 157.68C551.487 157.68 551.583 157.631 551.644 157.541C551.741 157.397 551.709 157.216 551.564 157.12C551.51 157.084 551.452 157.068 551.394 157.068Z"
        fill="#13223E"
      ></path>
      <path
        d="M555.985 156.689C555.888 156.832 555.705 156.871 555.561 156.776C555.416 156.681 555.383 156.499 555.48 156.357C555.577 156.215 555.76 156.175 555.904 156.271C556.049 156.366 556.081 156.547 555.985 156.689Z"
        fill="#231F20"
      ></path>
      <path
        d="M555.735 156.219C555.636 156.219 555.541 156.268 555.48 156.357C555.383 156.5 555.416 156.681 555.56 156.776C555.614 156.811 555.673 156.828 555.73 156.828C555.829 156.828 555.924 156.779 555.985 156.689C556.081 156.547 556.049 156.366 555.904 156.271C555.851 156.235 555.792 156.219 555.735 156.219Z"
        fill="#13223E"
      ></path>
      <path
        d="M552.939 161.241C552.843 161.384 552.658 161.423 552.513 161.33C552.368 161.236 552.332 161.055 552.43 160.912C552.527 160.769 552.71 160.729 552.855 160.823C553.001 160.918 553.035 161.098 552.939 161.241Z"
        fill="#231F20"
      ></path>
      <path
        d="M552.686 160.772C552.587 160.772 552.491 160.822 552.43 160.912C552.332 161.055 552.368 161.236 552.513 161.33C552.566 161.364 552.625 161.38 552.682 161.38C552.781 161.38 552.878 161.332 552.939 161.241C553.035 161.098 553.001 160.918 552.855 160.824C552.802 160.789 552.744 160.772 552.686 160.772Z"
        fill="#13223E"
      ></path>
      <path
        d="M548.023 155.183L548.07 155.161C548.084 155.161 548.096 155.166 548.102 155.17L548.076 155.277L548.023 155.183Z"
        fill="#231F20"
      ></path>
      <path
        d="M548.076 154.914C547.978 154.914 547.882 154.963 547.821 155.053C547.724 155.196 547.756 155.378 547.901 155.474C547.954 155.509 548.013 155.526 548.07 155.526C548.169 155.526 548.264 155.477 548.325 155.386C548.422 155.243 548.39 155.062 548.245 154.965C548.192 154.93 548.134 154.914 548.076 154.914Z"
        fill="#13223E"
      ></path>
      <path
        d="M546.158 153.854C546.061 153.997 545.878 154.037 545.733 153.941C545.588 153.846 545.556 153.663 545.653 153.521C545.75 153.378 545.933 153.337 546.077 153.433C546.222 153.529 546.254 153.71 546.158 153.854Z"
        fill="#231F20"
      ></path>
      <path
        d="M545.909 153.381C545.81 153.381 545.714 153.43 545.653 153.521C545.556 153.663 545.588 153.846 545.733 153.941C545.787 153.977 545.845 153.993 545.903 153.993C546.001 153.993 546.097 153.944 546.158 153.854C546.254 153.71 546.223 153.528 546.078 153.433C546.024 153.397 545.966 153.381 545.909 153.381Z"
        fill="#13223E"
      ></path>
      <path
        d="M559.497 160.939C559.4 161.081 559.217 161.118 559.073 161.024C558.929 160.93 558.892 160.75 558.988 160.607C559.085 160.465 559.268 160.427 559.413 160.522C559.557 160.616 559.593 160.796 559.497 160.939Z"
        fill="#231F20"
      ></path>
      <path
        d="M559.242 160.47C559.144 160.47 559.049 160.518 558.989 160.607C558.892 160.75 558.928 160.93 559.073 161.024C559.126 161.059 559.186 161.076 559.244 161.076C559.342 161.076 559.436 161.028 559.497 160.938C559.593 160.795 559.557 160.616 559.413 160.521C559.359 160.486 559.3 160.47 559.242 160.47Z"
        fill="#13223E"
      ></path>
      <path
        d="M553.601 166.563C556.642 166.364 558.322 165.084 561.121 163.979C561.926 163.662 562.585 163.073 562.573 162.207C562.531 159.129 562.684 157.902 562.966 156.675C563.22 155.566 563.005 155.114 563.005 155.114C563.005 155.114 561.257 160.47 561.126 161.488C560.366 162.327 554.868 163.264 553.601 163.618C553.522 164.834 553.556 165.377 553.601 166.563Z"
        fill="#13223E"
      ></path>
      <path
        d="M546.24 143.539C548.33 144.056 550.691 144.581 552.796 145.07C553.253 145.176 553.54 145.007 553.619 144.608C553.642 144.492 553.484 144.358 553.461 144.213C553.382 143.716 552.25 143.244 551.938 143.123C550.464 142.55 546.388 141.198 545.425 141.526C544.462 141.853 545.238 141.982 545.016 142.397C544.793 142.812 545.786 143.427 546.24 143.539Z"
        fill="#13223E"
      ></path>
      <path
        d="M547.846 146.398C547.836 146.74 547.564 146.987 547.224 146.979C546.884 146.972 546.627 146.711 546.638 146.369C546.649 146.027 546.921 145.779 547.26 145.787C547.6 145.796 547.857 146.056 547.846 146.398Z"
        fill="#262526"
      ></path>
      <path
        d="M543.43 144.251C543.43 144.251 542.041 148.937 541.246 151.111C541.06 151.62 541.373 152.172 541.905 152.273L543.802 152.635"
        fill="#E5AF7D"
      ></path>
      <path
        d="M543.43 144.251C543.43 144.251 542.041 148.937 541.246 151.111C541.06 151.62 541.373 152.172 541.905 152.273L543.802 152.635"
        stroke="#13223E"
        strokeWidth="0.728399"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M545.905 157.779C547.117 158.484 548.935 157.68 549.221 156.678"
        stroke="#13223E"
        strokeWidth="0.728399"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M579.429 131.35C585.359 124.009 555.746 118.05 553.803 118.147C549.02 118.377 545.608 119.929 545.095 125.438C545.05 125.9 545.031 126.105 544.896 126.214C544.672 126.393 544.12 126.316 542.607 126.688C540.164 127.284 543.289 138.341 543.623 137.078C543.95 135.821 545.723 136.801 546.377 137.083C550.219 138.66 553.361 137.288 557.747 137.006C558.446 136.955 560.043 136.942 560.915 137.481C561.152 137.622 560.301 144.756 561.154 146.545C561.391 147.046 562.795 147.244 563.09 146.545C563.465 145.647 564.218 144.493 564.767 143.706C565.914 142.064 568.337 141.754 569.696 143.224C570.223 143.793 570.555 144.557 570.54 145.436C570.489 148.258 568.674 150.422 568.681 151.172C568.681 151.98 570.477 154.725 571.201 154.84C573.542 155.231 573.529 150.447 575.639 147.754C577.268 145.67 584.578 134.178 579.429 131.35Z"
        fill="#13223E"
      ></path>
      <path
        d="M543.236 146.383L544.129 142.928L545.597 148.757L545.237 152.312L544.306 152.409L543.501 152.166L543.236 146.383Z"
        fill="#E5AF7D"
      ></path>
      <path
        d="M549.518 439.012C551.224 439.012 552.607 437.629 552.607 435.923V428.315H546.43V435.923C546.43 437.629 547.812 439.012 549.518 439.012Z"
        fill="#1a5970"
      ></path>
      <path
        d="M551.244 272.06C551.47 275.517 559.415 353.148 559.415 353.148C559.415 353.148 580.447 427.39 580.541 430.525C580.596 432.337 594.254 431.768 594.157 429.958C593.988 426.763 576.628 351.498 576.628 351.498C576.628 351.498 587.481 275.749 588.028 272.397L551.244 272.06Z"
        fill="#f5a623"
      ></path>
      <path
        d="M547.784 255.611C547.612 262.497 534.984 339.068 534.984 339.068L535.529 343.199L538.921 363.951C539.632 371.387 540.402 378.821 540.993 386.263C541.318 389.982 541.625 393.703 541.881 397.425C542.156 401.147 542.431 404.868 542.642 408.592C543.032 414.707 542.532 424.256 542.727 430.379C542.796 432.58 556.423 432.015 556.31 429.817C555.994 423.699 556.633 414.141 556.513 408.015C556.415 404.286 556.379 400.554 556.344 396.823C556.289 393.092 556.286 389.359 556.3 385.625C556.271 378.161 556.42 370.688 556.511 363.218L557.025 340.799L556.937 342.628L561.212 322.49C561.212 322.49 587.49 273.619 589.192 266.945L547.784 255.611Z"
        fill="#1a5970"
      ></path>
      <path
        d="M542.627 291.653L569.216 292.778C584.929 294.828 589.978 284.568 591.364 268.783L591.486 262.356L544.795 261.866L545.204 271.621C544.373 273.143 543.879 274.825 543.757 276.555L542.627 291.653Z"
        fill="#1a5970"
      ></path>
      <path
        d="M554.519 172.94C557.201 172.611 568.592 168.657 573.272 170.587C586.762 176.151 592.934 202.131 592.212 218.935C591.57 233.879 595.548 248.639 593.913 259.812C593.818 260.461 592.34 260.789 592.187 261.447C591.4 264.818 591.379 268.388 591.364 268.783C591.24 271.866 544.659 272.06 544.659 272.06C544.659 272.06 544.114 268.419 544.931 265.898C540.265 266.079 541.027 206.115 541.768 199.666C542.933 189.522 551.056 173.364 554.519 172.94Z"
        fill="#13223E"
      ></path>
      <path
        d="M577.728 173.351C575.582 171.11 574.077 167.684 573.496 163.643C573.374 162.795 572.476 162.3 571.701 162.665L552.994 169.589C552.186 169.808 552.008 174.87 552.008 174.87L577.728 173.351Z"
        fill="#13223E"
      ></path>
      <path
        d="M551.245 272.06C551.245 272.06 550.609 280.16 555.877 282.703"
        stroke="#13223E"
        strokeWidth="0.711559"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M553.387 435.064C554.065 434.852 555.117 436.204 555.155 436.915C555.263 438.992 555.321 443.964 554.554 444.987C553.444 446.467 518.485 446.467 518.485 444.987C518.485 443.507 544.011 434.66 546.23 435.4C548.087 436.018 550.971 435.822 553.387 435.064Z"
        fill="#13223E"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M534.769 434.763C534.977 434.43 535.416 434.329 535.75 434.537L544.288 439.885C544.621 440.094 544.722 440.533 544.514 440.866C544.305 441.199 543.866 441.3 543.533 441.091L534.994 435.743C534.661 435.535 534.56 435.096 534.769 434.763Z"
        fill="#1a5970"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M528.552 435.96C528.761 435.627 529.2 435.526 529.533 435.735L538.071 441.083C538.404 441.291 538.505 441.73 538.297 442.063C538.088 442.396 537.649 442.497 537.316 442.289L528.777 436.941C528.444 436.732 528.343 436.293 528.552 435.96Z"
        fill="#1a5970"
      ></path>
      <path
        d="M571.219 165.554L572.183 169.849"
        stroke="#FFE458"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M566.992 166.867L567.856 170.714"
        stroke="#FFE458"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M562.789 168.285L563.466 171.3"
        stroke="#FFE458"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M558.567 169.621L559.255 172.684"
        stroke="#FFE458"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M554.373 170.848L554.907 173.225"
        stroke="#FFE458"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M588.67 270.417L588.019 264.067"
        stroke="#FFE458"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M582.887 271.046L582.293 265.198"
        stroke="#FFE458"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M576.629 271.462L576.062 265.874"
        stroke="#FFE458"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M569.852 266.168L570.168 272.06"
        stroke="#FFE458"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M563.579 266.168L563.486 272.127"
        stroke="#FFE458"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M556.094 266.39L556.069 272.547"
        stroke="#FFE458"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M548.084 266.656L548.41 272.547"
        stroke="#FFE458"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M539.662 428.444L559.31 427.466"
        stroke="#13223E"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M578.88 428.607L595.353 426.604"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M550.016 146.458L568.348 144.251"
        stroke="#FFE458"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M564.656 150.507C564.656 150.507 564.989 151.681 566.104 151.525C568.174 151.234 570.2 145.684 568.693 144.235C567.454 143.044 564.667 144.426 564.753 146.676"
        fill="#E5AF7D"
      ></path>
      <path
        d="M564.656 150.507C564.656 150.507 564.989 151.681 566.104 151.525C568.174 151.234 570.2 145.684 568.693 144.235C567.454 143.044 564.667 144.426 564.753 146.676"
        stroke="#13223E"
        strokeWidth="0.784032"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M54.8039 435.325H46.123V541.775H54.8039V435.325Z"
        fill="#f5a623"
      ></path>
      <path
        d="M226.765 435.325H218.084V541.775H226.765V435.325Z"
        fill="#f5a623"
      ></path>
      <path
        d="M54.8039 435.324H46.123V541.774H54.8039V435.324Z"
        fill="white"
        fill-opacity="0.48"
      ></path>
      <path
        d="M226.765 435.324H218.084V541.774H226.765V435.324Z"
        fill="white"
        fill-opacity="0.48"
      ></path>
      <path
        d="M228.808 420.156H173.146V426.794H228.808V420.156Z"
        fill="#1a5970"
      ></path>
      <path
        d="M222.424 413.517H166.763V420.155H222.424V413.517Z"
        fill="#1a5970"
      ></path>
      <path
        d="M236.212 406.879H180.551V413.517H236.212V406.879Z"
        fill="#1a5970"
      ></path>
      <g opacity="0.6">
        <path
          opacity="0.89"
          d="M545.229 149.027L544.173 147.668L547.356 142.928L548.988 143.797L545.229 149.027Z"
          fill="white"
        ></path>
      </g>
      <path
        d="M546.901 149.839C548.47 149.839 549.741 148.292 549.741 146.383C549.741 144.475 548.47 142.928 546.901 142.928C545.332 142.928 544.061 144.475 544.061 146.383C544.061 148.292 545.332 149.839 546.901 149.839Z"
        fill="white"
        fill-opacity="0.3"
        stroke="#FFE458"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M528.714 451.52C529.595 451.52 530.31 450.805 530.31 449.924C530.31 449.043 529.595 448.328 528.714 448.328C527.833 448.328 527.118 449.043 527.118 449.924C527.118 450.805 527.833 451.52 528.714 451.52Z"
        fill="#13223E"
      ></path>
      <path
        d="M582.428 451.52C583.309 451.52 584.024 450.805 584.024 449.924C584.024 449.043 583.309 448.328 582.428 448.328C581.546 448.328 580.832 449.043 580.832 449.924C580.832 450.805 581.546 451.52 582.428 451.52Z"
        fill="#13223E"
      ></path>
      <path
        opacity="0.2"
        d="M557.67 545.637C619.848 545.637 670.254 543.908 670.254 541.775C670.254 539.643 619.848 537.914 557.67 537.914C495.491 537.914 445.086 539.643 445.086 541.775C445.086 543.908 495.491 545.637 557.67 545.637Z"
        fill="black"
      ></path>
      <path
        d="M500.203 176.409C501.573 181.188 503.511 178.732 508.444 179.35C509.901 179.532 517.007 185.188 517.954 187.41C518.902 189.632 518.92 193.392 518.92 193.392L507.809 200.421C507.809 200.421 502.4 198.092 499.108 194.081C496.326 190.691 495.496 185.714 496.218 183.871C497.795 179.847 500.021 175.774 500.021 175.774L500.203 176.409Z"
        fill="#E6AF7D"
      ></path>
      <path
        d="M539.239 240.725C535.509 239.474 533.022 236.691 531.084 234.524L530.067 233.391L506.869 203.649C503.74 198.785 505.837 196.883 510.701 193.754C515.566 190.625 519.555 190.286 522.683 195.15L538.538 215.04L556.975 181.841C561.056 177.743 568.806 178.212 572.903 182.295C577.001 186.376 578.818 194.597 574.736 198.695C574.736 198.695 548.777 241.295 539.239 240.725Z"
        fill="#13223E"
      ></path>
      <path
        opacity="0.2"
        d="M400 525.006C466.82 525.006 520.989 523.277 520.989 521.144C520.989 519.012 466.82 517.283 400 517.283C333.179 517.283 279.011 519.012 279.011 521.144C279.011 523.277 333.179 525.006 400 525.006Z"
        fill="black"
      ></path>
      <path
        d="M497.866 43.5835H306.508V515.982H497.866V43.5835Z"
        fill="#ffffff"
      ></path>
      <path
        d="M497.866 43.5835V515.982H306.508V43.5835H497.866ZM504.449 37.001H497.866H306.508H299.926V43.5835V515.982V522.565H306.508H497.866H504.449V515.982V43.5835V37.001Z"
        fill="#1a5970"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M482.584 215.945H312.031V211.945H482.584V215.945Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M482.584 273.903H312.031V269.903H482.584V273.903Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M482.584 355.094H312.031V351.094H482.584V355.094Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M482.584 453.52H312.031V449.52H482.584V453.52Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M482.584 129.009H312.031V125.009H482.584V129.009Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M385.627 76.4561H374.159V122.924H385.627V76.4561Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M399.032 76.4561H387.563V122.924H399.032V76.4561Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M439.244 76.4561H427.776V122.924H439.244V76.4561Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M452.649 76.4561H441.181V122.924H452.649V76.4561Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M466.053 76.4561H454.585V122.924H466.053V76.4561Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M479.457 76.4561H467.989V122.924H479.457V76.4561Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M432.299 446.413V439.398H364.301V446.413H432.299Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M438.276 439.399V432.384H370.279V439.399H438.276Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M436.549 432.385V425.371H377.379V432.385H436.549Z"
        fill="#1a5970"
      ></path>
      <path
        d="M422.957 425.217V418.203H366.641V425.217H422.957Z"
        fill="#1a5970"
      ></path>
      <path
        d="M414.213 347.349V335.881H354.16V347.349H414.213Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M317.582 347.35H329.05V287.296H317.582V347.35Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M330.136 347.35H341.604V287.296H330.136V347.35Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M459.35 264.687H470.818V229.711H459.35V264.687Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M445.637 264.687H457.105V229.711H445.637V264.687Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M426.722 264.067V252.599H373.192V264.067H426.722Z"
        fill="black"
        fill-opacity="0.55"
      ></path>
      <path
        d="M331.042 163.392H319.574V209.86H331.042V163.392Z"
        fill="#1a5970"
      ></path>
      <path
        d="M344.447 163.392H332.979V209.86H344.447V163.392Z"
        fill="#1a5970"
      ></path>
      <path
        d="M384.659 163.392H373.191V209.86H384.659V163.392Z"
        fill="#1a5970"
      ></path>
      <path
        d="M398.064 163.392H386.596V209.86H398.064V163.392Z"
        fill="#1a5970"
      ></path>
      <path
        d="M411.468 163.392H400V209.86H411.468V163.392Z"
        fill="#1a5970"
      ></path>
      <path
        d="M424.872 163.392H413.404V209.86H424.872V163.392Z"
        fill="#1a5970"
      ></path>
      <path
        d="M438.277 163.392H426.809V209.86H438.277V163.392Z"
        fill="#1a5970"
      ></path>
      <path
        d="M463.149 399.945H451.681V446.413H463.149V399.945Z"
        fill="#1a5970"
      ></path>
      <path
        d="M476.553 399.945H465.085V446.413H476.553V399.945Z"
        fill="#1a5970"
      ></path>
      <path
        d="M324.514 382.073H317.5V446.413H324.514V382.073Z"
        fill="#1a5970"
      ></path>
      <path
        d="M332.713 382.073H325.698V446.413H332.713V382.073Z"
        fill="#1a5970"
      ></path>
      <path
        d="M430.607 300.881H419.139V347.349H430.607V300.881Z"
        fill="#1a5970"
      ></path>
      <path
        d="M444.011 300.881H432.543V347.349H444.011V300.881Z"
        fill="#1a5970"
      ></path>
      <path
        d="M457.415 300.881H445.947V347.349H457.415V300.881Z"
        fill="#1a5970"
      ></path>
      <path
        d="M470.82 300.881H459.352V347.349H470.82V300.881Z"
        fill="#1a5970"
      ></path>
      <path
        d="M409.234 335.594V324.126H349.181V335.594H409.234Z"
        fill="#1a5970"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M549.229 400.732H561.912L631.96 538.3L624.831 541.93L557.008 408.732H554.133L486.31 541.93L479.181 538.3L549.229 400.732Z"
        fill="#f5a623"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M495.145 490.732H615.997V498.732H495.145V490.732Z"
        fill="#f5a623"
      ></path>
      <path
        d="M518.341 201.91L495.904 209.158L485.261 188.461L520.136 198.499L518.341 201.91Z"
        fill="#f5a623"
      ></path>
      <path
        d="M479.266 165.897L470.152 172.17L495.93 209.625L505.044 203.352L479.266 165.897Z"
        fill="#f5a623"
      ></path>
      <path
        d="M520.136 198.498L505.008 203.385L479.266 165.897L494.486 160.838L520.136 198.498Z"
        fill="#1a5970"
      ></path>
      <path
        d="M484.857 193.536C487.548 197.445 492.288 199.588 496.939 198.649C498.313 198.372 499.622 197.868 500.671 197.018C504.87 193.616 504.582 191.988 506.114 193.711C507.646 195.435 505.008 203.385 505.008 203.385L499.029 212.956C499.029 212.956 493.428 212.49 489.231 209.86C485.684 207.638 483.422 203.386 483.516 201.499C483.722 197.378 484.5 193.017 484.5 193.017L484.857 193.536Z"
        fill="#E6AF7D"
      ></path>
      <path
        d="M543.859 245.16C539.727 245.16 536.321 243.22 533.666 241.709L532.276 240.921L499.237 219.051C494.496 215.253 495.949 212.657 499.748 207.916C503.547 203.175 507.406 201.505 512.146 205.304L538.7 222.479L556.04 184.775C558.736 179.331 566.61 177.209 572.054 179.906C577.498 182.601 582.055 190.182 579.359 195.626C579.359 195.626 553.549 242.541 543.859 245.16Z"
        fill="#1a5970"
      ></path>
      <path
        d="M513.046 183.365C513.046 183.365 504.688 184.789 505.391 186.959C506.093 189.129 516.534 191.455 516.534 191.455L515.721 186.499L513.046 183.365Z"
        fill="#E6AF7D"
      ></path>
      <path
        d="M505.556 496.52C506.437 496.52 507.151 495.805 507.151 494.924C507.151 494.043 506.437 493.328 505.556 493.328C504.674 493.328 503.96 494.043 503.96 494.924C503.96 495.805 504.674 496.52 505.556 496.52Z"
        fill="#ffffff"
      ></path>
      <path
        d="M605.586 496.52C606.467 496.52 607.182 495.805 607.182 494.924C607.182 494.043 606.467 493.328 605.586 493.328C604.705 493.328 603.99 494.043 603.99 494.924C603.99 495.805 604.705 496.52 605.586 496.52Z"
        fill="#ffffff"
      ></path>
      <path
        d="M494.985 536.03H475.448V541.775H494.985V536.03Z"
        fill="#f5a623"
      ></path>
      <path
        d="M635.693 536.03H616.156V541.775H635.693V536.03Z"
        fill="#f5a623"
      ></path>
      <path
        d="M38.815 397.173L35.8066 398.018L38.977 409.308L41.9853 408.463L38.815 397.173Z"
        fill="#ffffff"
      ></path>
      <path
        d="M44.3056 396.594H42.2949V407.645H44.3056V396.594Z"
        fill="#ffffff"
      ></path>
      <path
        d="M43.907 394.919H42.6943V398.079H43.907V394.919Z"
        fill="#ffffff"
      ></path>
      <path
        d="M46.4878 398.546L45.252 409.527L47.25 409.752L48.4859 398.771L46.4878 398.546Z"
        fill="#ffffff"
      ></path>
      <path
        d="M47.0721 396.925L46.7188 400.065L47.9238 400.201L48.2771 397.061L47.0721 396.925Z"
        fill="#ffffff"
      ></path>
      <path
        d="M49.5513 400.054L48.3154 411.035L50.3135 411.26L51.5493 400.279L49.5513 400.054Z"
        fill="#ffffff"
      ></path>
      <path
        d="M50.1356 398.433L49.7822 401.573L50.9872 401.709L51.3406 398.569L50.1356 398.433Z"
        fill="#ffffff"
      ></path>
      <path
        d="M37.6914 408.062L51.7276 408.062L50.1954 426.807H39.2237L37.6914 408.062Z"
        fill="#1a5970"
      ></path>
      <path
        d="M53.2162 406.879H36.1992V409.85H53.2162V406.879Z"
        fill="#ffffff"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.1734 398.43C38.2468 398.696 38.0905 398.972 37.8243 399.045L35.6235 399.652L37.1416 404.458C37.2248 404.722 37.0788 405.003 36.8155 405.086C36.5521 405.169 36.2712 405.023 36.1881 404.76L34.3574 398.964L37.5584 398.081C37.8246 398.008 38.0999 398.164 38.1734 398.43Z"
        fill="#ffffff"
      ></path>
      <path
        d="M686.488 102.857C688.727 102.857 690.542 101.042 690.542 98.8032C690.542 96.5644 688.727 94.7495 686.488 94.7495C684.249 94.7495 682.435 96.5644 682.435 98.8032C682.435 101.042 684.249 102.857 686.488 102.857Z"
        fill="#ffffff"
      ></path>
      <path
        d="M30.7363 225.113C32.9752 225.113 34.7901 223.298 34.7901 221.06C34.7901 218.821 32.9752 217.006 30.7363 217.006C28.4975 217.006 26.6826 218.821 26.6826 221.06C26.6826 223.298 28.4975 225.113 30.7363 225.113Z"
        fill="#1a5970"
      ></path>
      <path
        d="M132.843 89.9897C137.267 89.9897 140.853 86.4035 140.853 81.9797C140.853 77.5559 137.267 73.9697 132.843 73.9697C128.419 73.9697 124.833 77.5559 124.833 81.9797C124.833 86.4035 128.419 89.9897 132.843 89.9897Z"
        fill="#1a5970"
      ></path>
      <path
        d="M759.195 240.728C763.618 240.728 767.204 237.142 767.204 232.718C767.204 228.295 763.618 224.708 759.195 224.708C754.771 224.708 751.185 228.295 751.185 232.718C751.185 237.142 754.771 240.728 759.195 240.728Z"
        fill="#FEFEFE"
      ></path>
      <path
        d="M606.982 342.201C609.221 342.201 611.036 340.386 611.036 338.147C611.036 335.909 609.221 334.094 606.982 334.094C604.744 334.094 602.929 335.909 602.929 338.147C602.929 340.386 604.744 342.201 606.982 342.201Z"
        fill="#FEFEFE"
      ></path>
      <path
        d="M565.339 400.185H545.803V405.929H565.339V400.185Z"
        fill="#f5a623"
      ></path>
      <path
        d="M201.303 250.874C200.056 249.281 198.881 247.662 197.777 246.019"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M191.795 235.471C182.088 214.79 182.719 192.307 193.572 176.839C212.658 149.638 257.361 154.562 281.331 170.037C320.17 195.112 323.574 260.162 303.254 301.711C274.006 361.517 188.54 387.077 122.808 361.014C65.1238 338.141 41.7588 283.094 33.9414 258.465"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke-dasharray="12.13 12.13"
      ></path>
      <path
        d="M32.2087 252.648C31.4199 249.808 30.9512 247.797 30.7363 246.835"
        stroke="#FFE458"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
      <path
        d="M130.63 395.471V379.641H75.6104V395.471H130.63Z"
        fill="#ffffff"
      ></path>
      <path
        d="M83.0024 344.863H70.918V468.893H83.0024V344.863Z"
        fill="#ffffff"
      ></path>
      <path
        d="M130.63 371.993V356.162H75.6104V371.993H130.63Z"
        fill="#ffffff"
      ></path>
      <path
        d="M136.672 344.863H124.588V468.893H136.672V344.863Z"
        fill="#ffffff"
      ></path>
      <path
        d="M240.422 426.807H32.4648V443.842H240.422V426.807Z"
        fill="#f5a623"
      ></path>
      <path
        d="M240.422 426.807H32.4648V443.842H240.422V426.807Z"
        fill="white"
        fill-opacity="0.48"
      ></path>
      <path
        d="M141.019 489.981H66.5725C63.9345 489.981 61.7959 487.843 61.7959 485.205V465.396C61.7959 462.758 63.9345 460.62 66.5725 460.62H141.019C143.657 460.62 145.796 462.758 145.796 465.396V485.205C145.796 487.843 143.657 489.981 141.019 489.981Z"
        fill="#ffffff"
      ></path>
    </svg>
  );
};

export default IllustrationBookshelf;
