import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { LocalStateContext } from "../State/LocalState";
import { Card } from "./DnD/Card";
import { YearStatsColumn } from "./YearStatsColumn";
import { styled } from "styled-components";

const ReadingHistoryYear = () => {
  const { finishedBooks } = useContext(LocalStateContext);
  let { year } = useParams();
  const books = finishedBooks[year];
  console.log("books", books);

  const MainContainer = styled.div`
    max-width: 100%;
    padding-left: 4rem; /* 64px */
    padding-right: 4rem; /* 64px */
    padding-top: 2rem; /* 32px */
    padding-bottom: 2rem; /* 32px */

    display: grid;
    grid-template-columns: repeat(9, minmax(0, 1fr));

    overflow: hidden;
  `;

  const BookContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    place-content: start;
    padding-top: 2.75rem; /* 32px */

    overflow: hidden;
    grid-column: span 7 / span 7;

    grid-auto-rows: auto;
    gap: 4rem; /* 64px */

    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `;

  const StatsContainer = styled.div`
    grid-column: span 2 / span 2;
  `;

  return (
    <MainContainer>
      <BookContainer>
        {books &&
          books.map((book, i) => (
            <Card
              key={book._id}
              index={i}
              id={book._id}
              text={book.text}
              image={book.image}
            />
          ))}
      </BookContainer>
      <StatsContainer>
        <YearStatsColumn books={books} year={year} />
      </StatsContainer>
    </MainContainer>
  );
};

export default ReadingHistoryYear;
