import React, { useContext } from "react";
import moment from "moment";

import { LocalStateContext } from "../State/LocalState";
import styled from "styled-components";
import BookMark from "../Icons/Bookmark";
import Trophy from "../Icons/Trophy";
import Pages from "../Icons/Pages";
import Book from "../Icons/Book";
import Calendar from "../Icons/Calendar";
import Exclamation from "../Icons/Exclamation";

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5fr 2fr;
  gap: 1px 1px;
  grid-template-areas: "." ".";
  width: 90%;
  margin: auto;
  padding: 20px;
  margin-top: 3em;
  border: 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-top: 6px solid #1a5970;
`;

const StatsItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas: "." "." "." "." ".";
`;

const StatsItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: ". .";
  line-height: 1;
  margin-bottom: 5px;
`;

const ItemIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  ${({ color }) => {
    switch (color) {
      case "one":
        return "fill: #fff; background: #1a7065;";
      case "two":
        return "fill: #fff; background: #1a6a70;";
      case "three":
        return "fill: #fff; background: #1a5970;";
      case "four":
        return "fill: #fff; background: #1a4870;";
      case "five":
        return "fill: #fff; background: #1a3770;";
      case "warning":
        return "fill: #fff; background: #ffc107;";
      default:
        return "fill: #fff; background: #1a4870;";
    }
  }};
`;

const ChallengeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`;

const ChallengeBox = styled.div`
  border: 2px solid #ffc107;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: ". .";
`;

const ChallengeIconContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas: "." "." ".";
  justify-items: center;
  align-items: center;
`;

const ChallengeTextContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas: "." ".";
`;

const ChallengeTextTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-items: center;
  align-items: center;
`;
const ChallengeTextBody = styled.div`
  color: #4a4a4a;
`;

export const StatsColumn = () => {
  const currentYear = moment().year();
  const daysToNewYear =
    Number(moment().diff(`${currentYear + 1}-01-01`, "days")) * -1;

  const { totalPages, booksRead, bookList, bookGoal } =
    useContext(LocalStateContext);

  let extraReading;
  if (bookList[bookGoal]) {
    daysToNewYear > 0
      ? (extraReading = (bookList[bookGoal].book.pages / daysToNewYear).toFixed(
          0
        ))
      : (extraReading = bookList[bookGoal].book.pages.toFixed(0));
  }

  return (
    <div>
      <StatsContainer>
        <StatsItemsContainer>
          <StatsItem>
            <ItemIcon color="one">
              <Trophy />
            </ItemIcon>

            <div>
              <h5>Reading Goal For {currentYear}:</h5>
              <p className="font-semibold">{bookGoal} books.</p>
            </div>
          </StatsItem>
          <StatsItem>
            <ItemIcon color="two">
              <Book />
            </ItemIcon>
            <div>
              <h5>Books Read This Year:</h5>
              <p className="font-semibold">{booksRead} books.</p>
            </div>
          </StatsItem>
          <StatsItem>
            <ItemIcon color="three">
              <Pages />
            </ItemIcon>
            <div>
              <h5>Pages Left To Read:</h5>
              <p className="font-semibold">
                {bookGoal <= bookList.length
                  ? `${totalPages} pages.`
                  : `Add more books to calculate.`}
              </p>
            </div>
          </StatsItem>
          <StatsItem>
            <ItemIcon color="four">
              <Calendar />
            </ItemIcon>
            <div>
              <h5>Days Left In Year:</h5>
              <p className="font-semibold">{daysToNewYear} days.</p>
            </div>
          </StatsItem>
          <StatsItem>
            <ItemIcon color="five">
              <BookMark />
            </ItemIcon>
            <div>
              <h5>Pages Per Day to Reach Goal:</h5>
              <p className="font-semibold">
                {bookGoal <= bookList.length
                  ? `${Math.round(totalPages / daysToNewYear)} pages per day.`
                  : `Add more books to calculate.`}
              </p>
            </div>
          </StatsItem>
        </StatsItemsContainer>
        {bookGoal <= bookList.length && (
          <ChallengeContainer>
            <ChallengeBox>
              <ChallengeIconContainer>
                <ItemIcon color="warning">
                  <Exclamation />
                </ItemIcon>
                <div></div>
              </ChallengeIconContainer>
              <ChallengeTextContainer>
                <ChallengeTextTitle>
                  <div>Like to challenge yourself?</div>
                </ChallengeTextTitle>
                <ChallengeTextBody>
                  Read {extraReading} more pages a day, and you will finish 1
                  extra book this year.
                </ChallengeTextBody>
              </ChallengeTextContainer>
            </ChallengeBox>
          </ChallengeContainer>
        )}
      </StatsContainer>
    </div>
  );
};
