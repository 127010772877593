import React, { useContext } from "react";
import DownArrowSquare from "../Icons/DownArrowSquare";
import Trash from "../Icons/Trash";
import UpArrowSquare from "../Icons/UpArrowSquare";

import { LocalStateContext } from "../State/LocalState";
import styled from "styled-components";
import Stars from "./Stars";
import CircleCheck from "../Icons/CircleCheck";

const ActionButton = styled.div`
  width: 50px;
  height: 50px;
  background-color: rgba(255, 250, 250, 0.6);
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  :hover {
    background-color: ${(props) =>
      props.delete ? "rgba(255, 87, 69, 0.6)" : "rgba(6, 69, 92,0.6)"};
    color: ${(props) => (props.delete ? "black" : "white")};
    cursor: pointer;
  }
`;

const HiddenActionButton = styled.div`
  width: 50px;
  height: 50px;

  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

const ReadButton = styled.div`
  width: 150px;
  height: 50px;
  background-color: rgba(255, 250, 250, 0.6);
  margin: 0px 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  color: #435d6b;
  :hover {
    /* background-color: ${(props) =>
      props.delete ? "rgba(255, 87, 69, 0.6)" : "rgba(6, 69, 92,0.6)"};
    color: ${(props) => (props ? "black" : "white")}; */
    cursor: pointer;
  }
`;

const Card = styled.div`
  border: 0;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;

  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  max-width: 600px;
  margin: auto;

  position: relative;
  display: flex;
  align-self: center;
  width: 100%;

  h4 {
    font-size: 24px;
    font-weight: 700;
  }
  p {
    // color: #435d6b;
    line-height: 1.5;
  }
  small {
    font-size: 16px;
    color: #667c8a;
  }
`;

const CardRow = styled.div`
  margin-right: 0;
  margin-left: 0;
  display: flex;
  flex-wrap: wrap;
`;

const CardImage = styled.div`
  padding-right: 0;
  padding-left: 0;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  position: relative;
  width: 100%;
  img {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    width: 100%;
    vertical-align: middle;
    border-style: none;
  }
`;

const CardInfo = styled.div`
  padding-right: 0;
  padding-left: 0;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  position: relative;
  width: 100%;
`;

const BookInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "."
    ".";
  height: 100%;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
`;

export const BookCard = (props) => {
  const {
    user,
    bookList,
    markBookAsComplete,

    updateUserData,
    sendBookToTop,
    sendBookToBottom,
    dragable,

    deleteBookFromUser,
  } = useContext(LocalStateContext);
  const {
    _id: id,
    title,
    image,
    author,
    series,
    releaseYear,
    pages,
    averageRating,
    ratingsCount,
  } = props.book.book;

  const { index } = props;

  const markCompleteFlow = async () => {
    await markBookAsComplete({ id, user });
    await deleteBookFromUser({ bookId: id, userId: user });
    updateUserData();
  };

  return (
    <div>
      <Card>
        <CardRow>
          <CardImage>
            <img src={image} className="card-img" alt="..." />
          </CardImage>
          <CardInfo>
            <BookInfo>
              <div>
                <h4 className="card-title mb-3">
                  {title} <small>by {author}</small>
                  {"  "}
                  <small className="text-muted">({releaseYear})</small>
                </h4>

                <p className="card-text mb-3 text-muted">{series}</p>
                <p className=" font-bold text-gray-800 text-xl">
                  {pages} pages
                </p>
              </div>
              <div>
                <Stars stars={averageRating} />
                <p className="card-text">
                  <small>from {ratingsCount} reviews.</small>
                </p>
              </div>
            </BookInfo>
          </CardInfo>
        </CardRow>
        {/* <button onClick={() => deleteBookFlow()}>DELETE</button>
        <button onClick={() => sendBookToTopFlow()}>
          <UpArrowSquare size="25px" />
          Send to Top
        </button>
        <button onClick={() => sendBookToBottomFlow()}>Send to Bottom</button> */}
        {dragable && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "0",
              left: "0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {index !== 0 && (
              <ActionButton onClick={() => sendBookToTop(id)}>
                <UpArrowSquare size="25px" />
              </ActionButton>
            )}

            {index === 0 && (
              <HiddenActionButton
                onClick={() => sendBookToTop(id)}
              ></HiddenActionButton>
            )}

            <ActionButton
              delete
              onClick={() => {
                console.log("id", id);
                console.log("user", user);
                deleteBookFromUser({ bookId: id, userId: user });
              }}
            >
              <Trash size="25px" />
            </ActionButton>

            {index !== bookList.length - 1 && (
              <ActionButton onClick={() => sendBookToBottom(id)}>
                <DownArrowSquare size="25px" />
              </ActionButton>
            )}
            {index === bookList.length - 1 && (
              <HiddenActionButton></HiddenActionButton>
            )}
          </div>
        )}
        <div
          style={{
            width: "50%",
            height: "100%",
            position: "absolute",
            bottom: "0",
            right: "0",

            display: "flex",

            flexDirection: "row",

            flexWrap: "nowrap",

            justifyContent: "flex-end",

            alignContent: "stretch",

            alignItems: "flex-end",
          }}
        >
          {dragable && index === 0 && (
            <ReadButton
              onClick={() => {
                markCompleteFlow();
              }}
            >
              Mark as Read
              <CircleCheck size="25px" fill="#667c8a" />
            </ReadButton>
          )}
        </div>
      </Card>
    </div>
  );
};
