import React, { useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { DragList } from "./DragList";
import { StatsColumn } from "./StatsColumn";

import { ControlColumn } from "./ControlColumn";
import { LocalStateContext } from "../State/LocalState";
import SetGoal from "./SetGoal";

export const Main = () => {
  let location = useLocation();
  const { user, bookGoal } = useContext(LocalStateContext);

  return (
    <div>
      <div className="grid-container" style={{ margin: "auto" }}>
        <Routes>
          <Route
            path="/auth"
            element={
              !user && (
                <Navigate to="/login" state={{ from: location }} replace />
              )
            }
          />
        </Routes>

        <div>
          <ControlColumn />
        </div>
        <div>
          <DragList />
        </div>
        <div>
          <div></div>
          {bookGoal && <StatsColumn />}
          {!bookGoal && <SetGoal />}
        </div>
      </div>
    </div>
  );
};
