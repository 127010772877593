import React from "react";

const Exclamation = ({ size = "1.5rem", fill = "#fff", stroke = "black" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      dataReactroot=""
    >
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
        stroke="#221b38"
        fill="#ffffff"
        d="M12.04 14C13.09 14 14.03 13.23 14.13 12.16L14.97 4.42C15.18 3.06 14.14 2 12.88 2H11.1C9.85001 2 8.90001 3.06 9.01001 4.32L9.85001 12.06C10.05 13.23 10.89 14 12.04 14Z"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
        stroke="#221b38"
        fill="#ffffff"
        d="M12 22C13.1046 22 14 21.1046 14 20C14 18.8954 13.1046 18 12 18C10.8954 18 10 18.8954 10 20C10 21.1046 10.8954 22 12 22Z"
      ></path>
    </svg>
  );
};

export default Exclamation;
