import React, { useState } from "react";

import "./App.css";

import Layout from "./Components/Layout";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { GridProvider } from "./Components/DnD/GridContext";

import { NoveListStateProvider } from "./State/LocalState";

import { NavBar } from "./Components/NavBar";
import { Main } from "./Components/Main";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Login } from "./Components/Login";
import { Register } from "./Components/Register";

import Shelf from "./Components/Shelf";
import ReadingHistory from "./Components/ReadingHistory";
import ReadingHistoryYear from "./Components/ReadingHistoryYear";

function App() {
  const [redirect, setRedirect] = useState(true);
  return (
    <NoveListStateProvider>
      <DndProvider backend={HTML5Backend}>
        <GridProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              LAYOUT
              <Route path="main" element={<Main />} />
              <Route path="Shelf" element={<Shelf />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="history">
                <Route index element={<ReadingHistory />} />
                <Route path=":year" element={<ReadingHistoryYear />} />
              </Route>
            </Route>
          </Routes>
        </GridProvider>
      </DndProvider>
    </NoveListStateProvider>
  );
}

export default App;
