const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const setReadingGoal = async ({ goal, userId }) => {
  console.log("CALLING SETREADINGGOAL MUTATION");

  const fetchData = await fetch(`${BACKEND_URL}/setreadinggoal`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: {
        goal,
        userId,
      },
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      console.log("DATA FROM SETREADINGGOAL MUTATION : ", data);
      return data.payload.goal;
    });
  return fetchData;
};
