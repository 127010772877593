const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const updateBookOrder = async ({ user, books }) => {
  console.log("CALLING UPDATE BOOKS ORDER");

  const formattedBooks = books.map((book, index) => {
    return {
      book: book.book._id,
      order: index,
    };
  });
  console.log("formattedBooks", formattedBooks);
  await fetch(`${BACKEND_URL}/readingDetails`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: {
        user,
        books: formattedBooks,
      },
    }),
  })
    .then((resp) => resp.json())
    .then((data) => console.log("DATA FROM MUTATION : ", data));
};
