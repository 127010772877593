import React, { useState, useContext } from "react";
import moment from "moment";
import styled from "styled-components";
import Book from "../Icons/Book";
import Exclamation from "../Icons/Exclamation";

import { LocalStateContext } from "../State/LocalState";

const GoalModal = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.25s ease;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GoalMessageContainer = styled.div`
  height: 500px;
  width: 750px;
  background-color: #fff;
  display: grid;
  border-radius: 5px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1.5fr 1fr;
  gap: 0px 0px;

  grid-template-areas:
    "."
    "."
    ".";
`;

const GoalMessageIcon = styled.div`
  margin-top: 20px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: #b4deed;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
`;

const GoalMessageBody = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 0.25fr 2fr;
  gap: 0px 0px;
  grid-template-areas:
    "."
    ".";
`;

const GoalMessageTitle = styled.div`
  padding-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: 600;
  border-radius: 5px;
`;

const GoalMessageText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #525252;
  font-size: 1.25rem;
  line-height: 1.75rem;
`;

const StyledInput = styled.input`
  margin: 0px 10px;
  width: 75px;
  border: ${({ error }) => (error ? "2px solid red" : "")};
`;

const GoalMessageError = styled.div`
  background-color: #e1534e;
  position: relative;
  display: inline-block;
  color: white;
  font-size: 16px;
  padding: 5px 0;
  top: 40px;
  width: 160px;
  left: 65px;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
`;

const GoalMessageActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
`;
const GoalMessageAction = styled.div`
  font-size: 20px;
  padding: 15px 30px;
  background-color: ${(props) => (props.primary ? "#1a5970" : "")};
  border-radius: 5px;
  border: ${(props) => (props.primary ? "" : "1px solid #1a5970")};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.primary ? "#fff" : "#1a5970")};
  font-weight: 600;
  width: 50%;
  height: 60px;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  :active {
    background-color: ${(props) => (props.primary ? "#1d657f" : "#fff")};
    border: ${(props) => (props.primary ? "" : "1px solid #1d657f")};
  }
`;

const SetGoalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 1fr;
  gap: 1px 1px;
  grid-template-areas: "." ".";
  width: 90%;
  margin: auto;

  padding: 20px;
  padding-top: 40px;
  margin-top: 3em;
  border: 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-top: 6px solid #1a5970;
`;

const AlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`;

const AlertBox = styled.div`
  border: 2px solid #ffc107;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: ". .";
  padding-bottom: 40px;
  padding-right: 25px;
`;

const AlertIconContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas: "." "." ".";
  justify-items: center;
  align-items: center;
`;

const AlertTextContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas: "." ".";
`;

const AlertTextTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-items: center;
  align-items: center;
`;
const AlertTextBody = styled.div`
  color: #4a4a4a;
`;

const ItemIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  ${({ color }) => {
    switch (color) {
      case "one":
        return "fill: #fff; background: #1a7065;";
      case "two":
        return "fill: #fff; background: #1a6a70;";
      case "three":
        return "fill: #fff; background: #1a5970;";
      case "four":
        return "fill: #fff; background: #1a4870;";
      case "five":
        return "fill: #fff; background: #1a3770;";
      case "warning":
        return "fill: #fff; background: #ffc107;";
      default:
        return "fill: #fff; background: #1a4870;";
    }
  }};
`;

const GoalButton = styled.div`
  font-size: 20px;
  padding: 15px 30px;
  background-color: #1a5970;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  width: 50%;
  height: 60px;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  :active {
    background-color: #1d657f;
  }
`;

const SetGoal = () => {
  const currentYear = moment().year();
  const [modal, setModal] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [goalInput, setGoalInput] = useState("");
  const { user, addReadingGoal } = useContext(LocalStateContext);
  const userId = user;

  const checkInput = () => {
    if (!Number.isInteger(goalInput)) {
      setInputError("Must be a number!");
      return;
    }
    if (goalInput == null || goalInput === "") {
      setInputError("Must be a number!");
      return;
    }
  };

  return (
    <SetGoalContainer>
      {modal && (
        <GoalModal>
          <GoalMessageContainer>
            <GoalMessageIcon>
              <Book stroke="#1a5970" fill="#b4deed" size="50px" />
            </GoalMessageIcon>
            <GoalMessageBody>
              <GoalMessageTitle>
                Reading Goal For {currentYear}
              </GoalMessageTitle>
              {inputError && <GoalMessageError>{inputError}</GoalMessageError>}
              {!inputError && <div></div>}
              <GoalMessageText>
                In {currentYear} I want to read{" "}
                <StyledInput
                  onChange={(e) => {
                    checkInput();
                    setInputError(false);
                    setGoalInput(Number(e.target.value.trim()));
                  }}
                  error={inputError}
                />{" "}
                books.
              </GoalMessageText>
            </GoalMessageBody>
            <GoalMessageActions>
              <GoalMessageAction
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </GoalMessageAction>
              <GoalMessageAction
                primary
                onClick={() => {
                  checkInput();
                  setModal(false);
                  addReadingGoal({
                    goal: Number(goalInput),
                    userId,
                  });
                }}
              >
                Set
              </GoalMessageAction>
            </GoalMessageActions>
          </GoalMessageContainer>
        </GoalModal>
      )}

      <AlertContainer>
        <AlertBox>
          <AlertIconContainer>
            <ItemIcon color="warning">
              <Exclamation />
            </ItemIcon>
            <div></div>
          </AlertIconContainer>
          <AlertTextContainer>
            <AlertTextTitle>
              <div>Want to set a reading goal for {currentYear}?</div>
            </AlertTextTitle>
            <AlertTextBody>
              You currently do not have a goal set for {currentYear}. Set a goal
              below so that you can challenge yourself to read more this year!
            </AlertTextBody>
          </AlertTextContainer>
        </AlertBox>
      </AlertContainer>

      <GoalButton
        onClick={() => {
          setModal(true);
        }}
      >
        Set New Goal
      </GoalButton>
    </SetGoalContainer>
  );
};

export default SetGoal;
