const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const updateUserDataMutation = async (user) => {
  console.log("CALLING UPDATE USER DATA MUTATION");

  const resData = await fetch(`${BACKEND_URL}/readingDetails`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: {
        user,
      },
    }),
  })
    .then((resp) => resp.json())
    .then((data) => data);

  return resData;
};
