import React from "react";
import { styled } from "styled-components";

import { NoveListStateProvider } from "../State/LocalState";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { GridProvider } from "./DnD/GridContext";
import { NavBar } from "./NavBar";

import { Outlet } from "react-router-dom";

import logo from "../open-book-svgrepo-com.svg";

const AppLogo = styled.img`
  height: 10vmin;
  pointer-events: none;
`;

const Header = styled.header`
  background-color: #1a5970;
  min-height: 20vh;
  display: flex;
  height: 250px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  span {
    color: #ffc107;
    border-bottom: 1px solid #ffc107;
    margin: 2px;
    margin-top: 0px;
  }
  h1 {
    margin: 2px;
    margin-top: 0px;
    line-height: 34px;
    height: 35px;
  }
`;

const SmallTitle = styled.div`
  font-size: calc(2px + 2vmin);
  font-weight: 600;
  margin: 0px;
`;

const Layout = () => {
  return (
    <div>
      <Header>
        <AppLogo src={logo} alt="logo" fill={"red"} />
        <SmallTitle>The</SmallTitle>
        <h1>
          nove
          <span>List</span>
        </h1>
      </Header>
      <NavBar />
      <Outlet />
    </div>
  );
};

export default Layout;
