import React from "react";

const Trophy = ({ size = "1.5rem", fill = "#fff" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      dataReactroot=""
    >
      <path
        fill={fill}
        d="M13.5899 12.76C13.4699 13.07 13.3599 13.46 13.3099 13.89C13.2599 14.28 13.2699 14.63 13.3099 14.92C13.3599 15.36 13.4699 15.75 13.5999 16.06C13.3999 16.02 13.1999 16 12.9999 16H10.9999C10.7999 16 10.5999 16.02 10.3999 16.06C10.5299 15.75 10.6399 15.36 10.6899 14.92C10.7299 14.63 10.7399 14.28 10.6899 13.89C10.6399 13.46 10.5299 13.07 10.4099 12.76C10.9199 12.92 11.4499 13 11.9999 13C12.5499 13 13.0799 12.92 13.5899 12.76Z"
        undefined="1"
      ></path>
      <path
        fill={fill}
        d="M16 19H8C8 17.34 9.34 16 11 16H13C14.66 16 16 17.34 16 19Z"
        undefined="1"
      ></path>
      <path
        fill={fill}
        d="M19 22H5C5 20.34 6.34 19 8 19H16C17.66 19 19 20.34 19 22Z"
        undefined="1"
      ></path>
      <path
        fill={fill}
        d="M17.8199 4H20.9999C20.9999 4 20.9599 7.45 16.8799 10C17.1799 9.4 17.3799 8.74 17.4399 8.04L17.8199 4Z"
        undefined="1"
      ></path>
      <path
        fill={fill}
        d="M6.18 4H3C3 4 3.04 7.45 7.12 10C6.82 9.4 6.62 8.74 6.56 8.04L6.18 4Z"
        undefined="1"
      ></path>
      <path
        fill={fill}
        d="M12 13C9.18 13 6.82 10.85 6.56 8.04L6 2H18L17.44 8.04C17.18 10.85 14.82 13 12 13Z"
        undefined="1"
      ></path>
    </svg>
  );
};

export default Trophy;
