import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import { LocalStateContext } from "../State/LocalState";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { styled } from "styled-components";

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #343a40;
  padding-right: 40px;
  ul {
    color: white;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  li {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  a {
    color: white;
    text-decoration: none;
  }
`;

export const NavBar = () => {
  let location = useLocation();
  const { user, setUser } = useContext(LocalStateContext);

  const [redirect, setRedirect] = useState(false);
  const logout = () => {
    localStorage.removeItem("user");
    setUser(false);
    setRedirect(true);
  };
  return (
    <div className="bg-dark text-white flex justify-between px-2">
      <Routes>
        <Route
          path="/auth"
          element={
            redirect && (
              <Navigate to="/login" state={{ from: location }} replace />
            )
          }
        />
      </Routes>

      {!user && (
        <>
          <div></div>
          <Bar>
            <ul class="nav text-white">
              <li class="nav-item ">
                <Link to="/login" className="nav-link text-white">
                  <strong>Login</strong>
                </Link>
              </li>
              <li class="nav-item ">
                <Link to="/register" className="nav-link text-white">
                  <strong>Register</strong>
                </Link>
              </li>
            </ul>
          </Bar>
        </>
      )}
      {user && (
        <>
          <Bar>
            <ul className="nav text-white">
              <li className="nav-item">
                <Link to="/Shelf" className="nav-link text-white">
                  <strong>Shelf View</strong>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/main" className="nav-link text-white">
                  <strong>List View</strong>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/history" className="nav-link text-white">
                  <strong>Reading History</strong>
                </Link>
              </li>
            </ul>

            <ul className="nav text-white">
              <li className="nav-item">
                <Link
                  to="/logout"
                  className="nav-link text-white"
                  onClick={() => logout()}
                >
                  <strong>Logout</strong>
                </Link>
              </li>
            </ul>
          </Bar>
        </>
      )}
    </div>
  );
};
