import React from "react";

const IllustrationReading = ({ width = "200", height = "150" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 800 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      dataReactroot=""
    >
      <path
        d="M194.812 270.173C206.11 268.695 217.883 268.695 230.131 270.173C230.131 270.173 221.621 282.085 221.487 282.085C221.353 282.085 200.088 276.129 200.088 276.129L194.812 270.173Z"
        fill="#232323"
      ></path>
      <path
        d="M435.094 233.546L449.338 228.239C449.338 228.239 462.186 213.157 464.141 212.878C464.141 212.878 470.286 206.454 479.503 206.733L475.523 216.159L470.705 225.516L463.024 235.292L455.972 243.741L440.261 251.282L435.094 233.546Z"
        fill="#E4B69D"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M449.737 239.822C451.222 248.525 445.371 256.785 436.667 258.27L358.216 271.66C349.513 273.146 341.253 267.294 339.768 258.591C338.282 249.888 344.133 241.628 352.837 240.143L431.288 226.753C439.992 225.267 448.251 231.119 449.737 239.822Z"
        fill="#232323"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M451.78 383.198C452.092 383.283 452.728 383.474 453.315 383.928C463.01 391.428 468.334 401.917 471.428 415.251C474.503 428.498 475.447 444.872 476.171 464.426C477.194 492.104 477.574 521.059 477.114 551.244L470.966 551.151C471.424 521.076 471.046 492.229 470.026 464.653C469.3 445.021 468.36 429.226 465.439 416.641C462.583 404.335 457.901 395.413 449.928 389.086C449.881 389.078 449.825 389.07 449.762 389.061C449.264 388.994 448.519 388.939 447.509 388.906C445.506 388.839 442.728 388.864 439.36 388.962C432.639 389.159 423.774 389.644 414.501 390.22C407.265 390.669 399.765 391.175 392.87 391.64C384.18 392.227 376.45 392.748 371.418 393.01L374.695 551.883L368.547 552.009L365.146 387.109L368.174 387.001C372.636 386.843 381.676 386.233 392.249 385.519C399.168 385.052 406.742 384.541 414.12 384.083C423.403 383.506 432.352 383.016 439.18 382.816C442.586 382.717 445.516 382.687 447.714 382.76C448.806 382.797 449.785 382.86 450.579 382.967C450.974 383.02 451.389 383.092 451.78 383.198ZM450.169 389.132C450.204 389.142 450.196 389.142 450.156 389.129C450.162 389.13 450.166 389.131 450.169 389.132Z"
        fill="#CACACA"
      ></path>
      <path
        d="M464.58 379.969L364.533 394.74L272.916 382.697L359.924 371.876L464.58 379.969Z"
        fill="#CACACA"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M310.8 389.779C330.667 392.067 352.071 395.506 363.745 399.882C369.727 402.124 373.902 407.964 376.942 414.459C380.082 421.168 382.528 429.703 384.448 439.018C388.296 457.689 390.211 480.414 390.94 500.132C391.964 527.82 392.538 558.344 392.078 588.581L382.241 588.431C382.698 558.408 382.129 528.061 381.109 500.495C380.389 481.026 378.503 458.912 374.812 441.004C372.963 432.031 370.705 424.342 368.032 418.629C365.259 412.704 362.514 409.927 360.293 409.094C349.907 405.201 329.66 401.854 309.675 399.553C293.045 397.638 277.243 396.517 268.393 396.346L254.937 563.163L245.131 562.372L259.303 386.68L263.679 386.525C271.507 386.247 290.846 387.482 310.8 389.779Z"
        fill="#151516"
      ></path>
      <path
        d="M258.982 394.594L369.816 409.512V392.572L258.982 379.288V394.594Z"
        fill="#151516"
      ></path>
      <path
        d="M474.193 394.59L369.817 409.512L363.486 391.814L464.581 379.969L474.193 394.59Z"
        fill="#CACACA"
      ></path>
      <path
        d="M222.717 147.636L258.981 379.288L289.904 378.839C289.904 378.839 248.149 152.134 222.717 147.636Z"
        fill="#151516"
      ></path>
      <path
        d="M258.981 379.288C258.981 379.288 246.265 162.495 222.717 147.636C222.717 147.636 304.517 141.125 316.873 147.951C329.229 154.777 360.941 378.973 360.941 378.973C360.941 378.973 282.883 387.137 258.981 379.288Z"
        fill="#CACACA"
      ></path>
      <path
        d="M539.661 172.966C539.661 172.966 496.726 283.455 434.844 296.42C424.751 298.535 506.236 199.031 506.236 199.031L539.661 172.966Z"
        fill="#B4B4B4"
      ></path>
      <path
        d="M541.457 548.482H500.335L455.753 381.76C455.753 381.76 342.335 388.273 328.566 383.644C314.796 379.015 339.479 363.094 339.479 363.094L445.012 325.636C456.393 321.793 472.419 322.281 483.266 327.442L484.675 328.933C488.577 333.065 490.885 338.448 491.189 344.123L541.457 548.482Z"
        fill="#1a5970"
      ></path>
      <path
        d="M541.457 548.482H500.335L455.753 381.76C455.753 381.76 342.335 388.273 328.566 383.644C314.796 379.015 339.479 363.094 339.479 363.094L445.012 325.636C456.393 321.793 472.419 322.281 483.266 327.442L484.675 328.933C488.577 333.065 490.885 338.448 491.189 344.123L541.457 548.482Z"
        fill="black"
        fill-opacity="0.26"
      ></path>
      <path
        d="M594.853 417.123L475.409 290.637C471.435 286.428 465.529 284.645 459.891 285.957C422.882 294.572 297.257 323.078 294.73 332.765C291.904 343.597 290.417 381.996 317.733 385.764C333.25 387.904 365.648 390.721 393.44 376.972C407.102 370.214 428.114 348.338 437.64 337.967C442.473 332.705 453.959 326.579 467.279 327.442C489.965 328.913 494.18 363.966 496.671 366.415L574.479 443.712L594.853 417.123Z"
        fill="#1a5970"
      ></path>
      <path
        d="M508.67 572.103L527.057 571.965L521.975 540.388L502.367 546.229L508.67 572.103Z"
        fill="#E4B69D"
      ></path>
      <path
        d="M525.33 560.325C525.33 560.325 560.288 573.471 581.327 577.401C586.999 578.46 585.468 590 585.468 590L506.091 589.365C504.795 589.366 503.699 588.419 503.521 587.135C502.886 582.553 501.79 570.119 505.245 565.75C505.94 564.872 507.644 565.596 509.221 566.094C510.971 566.647 514.102 575.321 524.505 566.4C525.48 565.564 524.199 561.487 525.33 560.325Z"
        fill="#ffffff"
      ></path>
      <path
        d="M495.764 561.802L548.515 553.821L544.123 531.37L493.422 546.321L495.764 561.802Z"
        fill="#ffffff"
      ></path>
      <path
        d="M598.626 453.942L610.179 440.921L590.6 419.63L577.102 434.844L598.626 453.942Z"
        fill="#E4B69D"
      ></path>
      <path
        d="M574.245 451.074L609.728 421.57L595.116 404.583L565.586 440.66L574.245 451.074Z"
        fill="#ffffff"
      ></path>
      <path
        d="M605.719 434.737C605.719 434.737 646.755 433.847 667.864 430.312C673.554 429.359 676.08 440.722 676.08 440.722L597.625 468.619C596.408 469.064 595.053 468.552 594.445 467.408C592.275 463.322 586.975 452.021 588.72 446.731C589.07 445.667 590.92 445.762 592.572 445.689C594.405 445.607 600.325 452.678 607.031 440.726C607.66 439.606 605.056 436.217 605.719 434.737Z"
        fill="#ffffff"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M471.417 277.513C474.677 278.157 477.735 279.804 479.327 282.893C481.295 286.714 480.894 292.605 476.964 295.882C474.805 297.682 471.921 298.398 469.286 298.213C466.665 298.03 464.137 296.938 462.803 294.931C462.039 293.781 461.637 292.279 461.647 290.883C461.657 289.505 462.075 288.062 463.159 287.208C464.3 286.309 465.996 286.204 467.697 286.713C469.425 287.23 471.28 288.406 472.891 290.278C473.157 290.587 473.122 291.052 472.813 291.318C472.504 291.584 472.038 291.549 471.773 291.24C470.323 289.556 468.7 288.553 467.274 288.126C465.823 287.692 464.691 287.879 464.072 288.367C463.486 288.829 463.131 289.74 463.123 290.894C463.115 292.029 463.449 293.236 464.032 294.114C465.029 295.614 467.049 296.577 469.389 296.742C471.714 296.905 474.202 296.264 476.019 294.749C479.318 291.999 479.731 286.901 478.015 283.569C476.699 281.014 474.122 279.551 471.131 278.961C468.14 278.371 464.84 278.68 462.272 279.57C459.621 280.49 456.877 282.333 454.918 284.704C452.96 287.074 451.834 289.906 452.262 292.831C452.82 296.651 455.814 299.121 459.002 300.687C462.17 302.244 465.347 302.813 465.916 302.907L465.795 303.635L465.916 302.907C466.829 303.059 476.187 304.433 482.891 297.382C488.322 291.669 488.1 284.549 488.037 283.268C488.017 282.861 488.331 282.514 488.738 282.494C489.145 282.474 489.491 282.788 489.511 283.195C489.579 284.567 489.83 292.225 483.96 298.399C476.705 306.03 466.648 304.525 465.674 304.363C465.055 304.26 461.711 303.663 458.351 302.011C455.01 300.369 451.466 297.59 450.802 293.045L451.532 292.938L450.802 293.045C450.296 289.588 451.644 286.35 453.78 283.764C455.915 281.18 458.887 279.182 461.789 278.176C464.592 277.204 468.158 276.87 471.417 277.513Z"
        fill="#232323"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M469.097 324.624C466.106 325.214 463.529 326.677 462.213 329.232L461.557 328.894L462.213 329.232C460.497 332.564 460.91 337.662 464.209 340.412C466.026 341.927 468.514 342.568 470.839 342.405C473.179 342.24 475.2 341.278 476.196 339.778C476.78 338.9 477.114 337.692 477.105 336.557C477.097 335.403 476.742 334.492 476.156 334.03C475.537 333.542 474.406 333.355 472.954 333.79C471.528 334.216 469.905 335.219 468.455 336.903C468.19 337.212 467.724 337.247 467.415 336.981C467.106 336.715 467.071 336.25 467.337 335.941C468.948 334.069 470.803 332.893 472.531 332.376C474.232 331.867 475.928 331.972 477.069 332.871L476.623 333.438L477.069 332.871C478.153 333.725 478.571 335.169 478.581 336.546C478.591 337.942 478.189 339.445 477.425 340.594L476.811 340.186L477.425 340.594C476.091 342.602 473.563 343.693 470.943 343.877C468.307 344.062 465.423 343.345 463.264 341.545C459.334 338.269 458.933 332.377 460.901 328.556C462.493 325.467 465.551 323.82 468.811 323.176C472.071 322.533 475.636 322.867 478.439 323.839C481.341 324.846 484.313 326.844 486.448 329.427C488.584 332.013 489.932 335.251 489.427 338.708C488.762 343.253 485.219 346.033 481.877 347.675C478.517 349.326 475.173 349.923 474.554 350.026C473.58 350.188 463.523 351.693 456.268 344.062C450.399 337.888 450.649 330.231 450.717 328.858C450.737 328.451 451.084 328.137 451.491 328.157C451.898 328.178 452.211 328.524 452.191 328.931C452.128 330.213 451.906 337.332 457.337 343.045C464.041 350.096 473.399 348.723 474.313 348.571L474.433 349.298L474.313 348.571C474.881 348.476 478.058 347.907 481.226 346.35C484.414 344.784 487.408 342.314 487.967 338.494C488.394 335.569 487.269 332.737 485.31 330.367C483.351 327.996 480.607 326.153 477.956 325.233L478.197 324.537L477.956 325.233C475.388 324.343 472.088 324.034 469.097 324.624Z"
        fill="#232323"
      ></path>
      <path
        d="M514.331 134.514L539.661 172.966L468.808 278.512L412.938 318.42L423.817 279.849C423.817 279.849 450.598 254.537 463.952 233.242C477.305 211.947 514.331 134.514 514.331 134.514Z"
        fill="#E1E0DB"
      ></path>
      <path
        d="M425.631 263.772L444.932 261.188L474.839 244.41L488.364 244.159C488.364 244.159 493.376 250.103 492.158 253.702L490.21 254.155C490.21 254.155 493.699 259.663 491.51 261.118C489.321 262.574 488.333 265.804 488.333 265.804C488.333 265.804 490.447 268.788 484.744 272.797C484.744 272.797 482.827 280.192 478.826 280.646C474.825 281.101 456.969 286.509 447.671 284.56L430.987 286.853C430.987 286.853 426.433 269.272 425.631 263.772Z"
        fill="#E4B69D"
      ></path>
      <path
        d="M296.501 136.669C296.008 136.742 294.15 144.879 288.696 149.581C278.947 157.984 269.198 163.445 264.014 174.795C257.022 190.103 254.46 207.344 257.08 224.506L293.784 342.199L387.318 318.42L373.441 199.124C369.343 173.695 330.03 135.249 323.387 131.364L296.501 136.669Z"
        fill="#ffffff"
      ></path>
      <path
        d="M260.271 574.311V324.513C260.271 316.673 266.627 310.318 274.466 310.318H277.173C285.012 310.318 291.367 316.673 291.367 324.513V574.311C291.367 582.151 285.012 588.506 277.173 588.506H274.466C266.627 588.506 260.271 582.151 260.271 574.311Z"
        fill="#f5a623"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M260.268 566.621V574.311C260.268 582.151 266.623 588.506 274.462 588.506H277.169C285.008 588.506 291.364 582.151 291.364 574.311V566.621H260.268ZM291.364 338.214V324.513C291.364 316.673 285.008 310.318 277.169 310.318H274.462C266.623 310.318 260.268 316.673 260.268 324.513V338.214H291.364Z"
        fill="black"
        fill-opacity="0.36"
      ></path>
      <path
        d="M414.88 328.492H136.755C129.159 328.492 123 322.333 123 314.736C123 307.139 129.159 300.981 136.755 300.981H414.88C422.477 300.981 428.636 307.139 428.636 314.736C428.636 322.333 422.477 328.492 414.88 328.492Z"
        fill="#f5a623"
      ></path>
      <path
        d="M330.297 589.77H221.339C217.488 589.77 214.367 586.649 214.367 582.799V581.194C214.367 577.344 217.488 574.222 221.339 574.222H330.297C334.147 574.222 337.269 577.344 337.269 581.194V582.799C337.269 586.649 334.147 589.77 330.297 589.77Z"
        fill="#f5a623"
      ></path>
      <path
        d="M326.529 153.964L328.525 123.124L304.036 114.017L302.679 141.359C302.273 148.41 326.529 153.964 326.529 153.964Z"
        fill="#E4B69D"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M297.159 137.204C298.238 136.506 299.679 136.814 300.378 137.893C302.068 140.504 305.256 143.622 311.185 146.767C320.011 151.449 326.214 151.003 330.079 149.662C332.052 148.977 333.508 148.027 334.458 147.262C334.933 146.88 335.278 146.547 335.496 146.322C335.604 146.209 335.68 146.124 335.724 146.074C335.745 146.049 335.759 146.032 335.764 146.026C335.765 146.025 335.765 146.024 335.766 146.024C336.561 145.023 338.017 144.851 339.024 145.641C340.036 146.434 340.213 147.897 339.419 148.909L337.65 147.521C339.419 148.909 339.418 148.91 339.417 148.912L339.415 148.915L339.409 148.921L339.396 148.938C339.386 148.95 339.374 148.966 339.359 148.983C339.33 149.019 339.292 149.064 339.245 149.119C339.15 149.229 339.018 149.376 338.848 149.552C338.51 149.903 338.02 150.371 337.376 150.89C336.089 151.926 334.171 153.17 331.605 154.06C326.39 155.87 318.846 156.1 309.003 150.879C302.576 147.47 298.701 143.869 296.47 140.423C295.771 139.344 296.08 137.903 297.159 137.204Z"
        fill="#232323"
      ></path>
      <path
        d="M328.208 128.63L304.391 121.259C304.391 121.259 323.844 143.439 327.483 141.243L328.208 128.63Z"
        fill="#161F40"
      ></path>
      <path
        d="M328.071 129.03C332.309 130.048 336.752 130.174 340.96 129.037C351.129 126.29 358.149 117.665 358.818 106.015L360.803 71.5039C361.655 56.6765 350.326 43.9653 335.499 43.1128L317.996 42.1064C303.169 41.2539 286.78 48.4388 285.928 63.2662L289.437 107.973C290.959 119.081 317.821 126.567 328.071 129.03Z"
        fill="#E4B69D"
      ></path>
      <path
        d="M380.258 42.5455C381.163 37.1139 382.593 28.7029 377.128 24.5073C371.957 20.5373 363.432 21.9885 359.769 26.1879C356.501 29.9342 357.425 35.4974 356.553 35.5274C355.193 35.5741 356.793 21.9195 349.355 15.7671C344.697 11.9134 336.625 11.1131 333.056 14.5323C327.119 20.2209 331.271 29.1265 329.977 29.5564C328.529 30.0377 327.884 15.1604 314.817 10.8048C306.576 8.0578 295.563 12.8121 294.76 17.9854C293.559 25.7233 314.921 36.1773 315.164 35.8263C315.378 35.5182 302.519 22.2508 290.511 24.5868C285.54 25.5539 280.851 29.1689 278.955 34.042C275.924 41.8337 280.173 51.1691 286.136 54.099C289.672 55.8365 291.099 53.9918 300.051 54.0549C307.615 54.1082 313.314 55.4727 319.528 56.9606C328.779 59.1756 329.507 60.5754 336.26 62.3385C344.199 64.4109 360.882 68.7663 371.542 59.9513C378.287 54.3728 379.692 45.9415 380.258 42.5455Z"
        fill="#151516"
      ></path>
      <path
        d="M326.992 55.0558C326.992 55.0558 314.512 75.644 314.845 84.75C315.178 93.8561 303.6 90.2282 303.6 90.2282C303.6 90.2282 302.295 67.798 291.606 73.1982C281.152 78.4796 293.541 106.025 290.246 106.37C282.771 107.152 278.812 79.9649 279.275 68.1909C279.588 60.212 278.477 44.516 282.528 43.9998C292.558 42.7221 326.992 55.0558 326.992 55.0558Z"
        fill="#151516"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M351.43 79.6919C351.586 80.9679 350.678 82.1289 349.402 82.2851L338.132 83.6645C336.856 83.8207 335.695 82.9129 335.539 81.6369C335.383 80.3609 336.291 79.1998 337.567 79.0437L348.837 77.6642C350.113 77.5081 351.274 78.4159 351.43 79.6919Z"
        fill="#151516"
      ></path>
      <path
        d="M358.235 82.1303C361.018 90.5045 361.762 96.429 361.975 99.9059C362.072 101.489 362.119 103.549 360.812 104.601C358.563 106.413 354.026 103.85 353.578 103.59"
        fill="#E4B69D"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M358.041 81.5468C358.363 81.4397 358.711 81.6141 358.818 81.9364C361.619 90.3643 362.373 96.3416 362.588 99.8684C362.637 100.661 362.678 101.633 362.526 102.553C362.374 103.474 362.015 104.422 361.198 105.08L360.812 104.601L361.198 105.08C360.495 105.646 359.641 105.847 358.804 105.859C357.969 105.87 357.11 105.694 356.341 105.461C354.802 104.993 353.508 104.26 353.27 104.122C352.976 103.952 352.876 103.576 353.046 103.282C353.216 102.988 353.592 102.888 353.886 103.058C354.097 103.18 355.295 103.858 356.698 104.284C357.4 104.497 358.125 104.638 358.787 104.629C359.447 104.62 360.004 104.463 360.426 104.122C360.915 103.729 361.186 103.12 361.313 102.353C361.44 101.583 361.409 100.734 361.361 99.9435L361.361 99.9435C361.151 96.5165 360.417 90.6448 357.651 82.3242C357.544 82.002 357.718 81.6539 358.041 81.5468Z"
        fill="#151516"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M344.248 110.259C344.596 110.107 345.001 110.267 345.153 110.615C345.305 110.966 346.358 113.263 348.957 114.066L348.754 114.723L348.957 114.066C351.042 114.71 352.802 113.988 353.261 113.782C353.607 113.626 354.014 113.781 354.17 114.128C354.326 114.474 354.171 114.881 353.824 115.037C353.269 115.286 351.122 116.174 348.551 115.38L348.551 115.38C345.342 114.389 344.075 111.586 343.891 111.164C343.74 110.815 343.899 110.41 344.248 110.259Z"
        fill="#151516"
      ></path>
      <path
        d="M347.313 91.8702C348.412 91.8702 349.302 90.9798 349.302 89.8813C349.302 88.7828 348.412 87.8924 347.313 87.8924C346.215 87.8924 345.324 88.7828 345.324 89.8813C345.324 90.9798 346.215 91.8702 347.313 91.8702Z"
        fill="#151516"
      ></path>
      <path
        d="M300.37 77.0517C298.825 74.9041 296.509 73.48 293.853 73.3273C288.828 73.0384 284.491 77.4086 284.164 83.0883C283.837 88.7681 287.646 93.6066 292.67 93.8955C294.806 94.0183 296.818 93.2992 298.45 91.9954"
        fill="#E4B69D"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M282.937 83.0178C283.294 76.8058 288.085 71.7639 293.924 72.0996C296.995 72.2762 299.635 73.9234 301.369 76.3335C301.766 76.8848 301.641 77.6533 301.089 78.0499C300.538 78.4466 299.77 78.3212 299.373 77.7699C298.017 75.8848 296.024 74.6839 293.783 74.5551C289.574 74.313 285.689 78.0114 285.393 83.1589C285.097 88.3064 288.533 92.4258 292.742 92.6678C294.547 92.7716 296.266 92.167 297.684 91.0345C298.214 90.6107 298.988 90.6973 299.412 91.2279C299.836 91.7586 299.749 92.5324 299.219 92.9563C297.372 94.4314 295.067 95.265 292.601 95.1233C286.761 94.7875 282.58 89.2298 282.937 83.0178Z"
        fill="#151516"
      ></path>
      <path
        d="M481.598 212.249C481.598 212.249 483.553 219.162 478.945 222.164C474.336 225.167 467.144 225.586 467.144 225.586C467.144 225.586 466.586 234.593 459.533 235.292C452.481 235.99 457.089 219.162 460.441 218.324C463.793 217.486 467.005 218.464 470.566 217.626C474.127 216.788 478.945 211.411 481.598 212.249Z"
        fill="#E4B69D"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M287.887 179.617C299.67 176.361 311.862 183.274 315.118 195.058L333.181 260.436L415.655 254.577C427.849 253.711 438.436 262.894 439.303 275.088C440.169 287.282 430.986 297.87 418.792 298.736L326.412 305.299C311.145 306.383 297.231 296.552 293.154 281.799L293.154 281.799L272.446 206.848C269.19 195.064 276.103 182.873 287.887 179.617Z"
        fill="#ffffff"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M287.887 179.617C299.67 176.361 311.862 183.274 315.118 195.058L333.181 260.436L415.655 254.577C427.849 253.711 438.436 262.894 439.303 275.088C440.169 287.282 430.986 297.87 418.792 298.736L326.412 305.299C311.145 306.383 297.231 296.552 293.154 281.799L293.154 281.799L272.446 206.848C269.19 195.064 276.103 182.873 287.887 179.617Z"
        fill="white"
        fill-opacity="0.15"
      ></path>
      <path
        d="M512.439 200.953L499.172 172.095C501.999 166.215 505.759 160.533 507.845 151.304L522.408 183.798C521.076 191.346 517.067 194.711 512.439 200.953Z"
        fill="#B4B4B4"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M504.572 205.858L494.996 183.094L496.13 182.618L505.706 205.381L504.572 205.858Z"
        fill="#B4B4B4"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M499.783 215.355L490.207 192.592L491.341 192.115L500.917 214.878L499.783 215.355Z"
        fill="#B4B4B4"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M309.701 186.423C303.391 178.665 293.864 178.288 284.905 181.529C284.601 181.639 284.266 181.481 284.156 181.178C284.046 180.874 284.203 180.539 284.507 180.429C293.703 177.103 303.854 177.381 310.608 185.685C311.673 186.993 312.774 188.972 313.885 191.395C314.999 193.827 316.14 196.741 317.275 199.945C319.545 206.354 321.804 213.95 323.807 221.225C325.81 228.502 327.558 235.464 328.806 240.607C329.43 243.179 329.928 245.296 330.271 246.771C330.442 247.508 330.575 248.085 330.664 248.477C330.709 248.673 330.743 248.823 330.766 248.925L330.801 249.078C330.801 249.078 330.801 249.078 330.231 249.206C329.66 249.335 329.66 249.334 329.66 249.334L329.626 249.183C329.603 249.082 329.569 248.933 329.525 248.737C329.435 248.346 329.303 247.771 329.132 247.035C328.79 245.564 328.292 243.451 327.67 240.883C326.424 235.747 324.679 228.797 322.68 221.536C320.68 214.272 318.429 206.706 316.173 200.336C315.044 197.15 313.917 194.271 312.822 191.882C311.723 189.485 310.671 187.614 309.701 186.423ZM330.359 249.777C330.044 249.848 329.731 249.649 329.66 249.334L330.231 249.206L330.801 249.078C330.872 249.393 330.674 249.706 330.359 249.777Z"
        fill="#232323"
      ></path>
      <path
        d="M221.489 298.662H203.459L192.532 273.663C191.812 272.017 193.018 270.173 194.815 270.173C213.281 273.733 213.008 273.601 230.133 270.173C231.93 270.173 233.136 272.017 232.416 273.663L221.489 298.662Z"
        fill="#ffffff"
      ></path>
      <path
        d="M198.95 291.584C202.629 289.46 203.758 284.53 201.473 280.572C199.188 276.615 194.354 275.128 190.676 277.252C186.998 279.375 185.868 284.305 188.153 288.263C190.438 292.221 195.272 293.707 198.95 291.584Z"
        fill="#ffffff"
      ></path>
      <path
        d="M220.587 302.002H203.547C203.547 302.002 184.298 300.635 183.593 300.206C182.888 299.777 183.011 298.662 183.593 298.422C183.947 297.93 242.706 298.269 243.38 298.715C244.053 299.161 244.036 299.97 243.38 300.206C242.724 300.441 220.587 302.002 220.587 302.002Z"
        fill="#ffffff"
      ></path>
    </svg>
  );
};

export default IllustrationReading;
