import React from "react";

const Book = ({ size = "1.5rem", fill = "#fff", stroke = "black" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      dataReactroot=""
    >
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
        stroke={stroke}
        fill={fill}
        d="M20 22H6C4.9 22 4 21.1 4 20V4C4 2.9 4.9 2 6 2H20V22Z"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
        stroke={stroke}
        fill={fill}
        d="M20 22H6C4.9 22 4 21.1 4 20C4 18.9 4.9 18 6 18H20V22Z"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
        stroke={stroke}
        d="M8 12H16"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1"
        stroke={stroke}
        d="M8 8H16"
      ></path>
    </svg>
  );
};

export default Book;
